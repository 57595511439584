// Direct copy from https://github.com/mmz-srf/nora/blob/main/frontend/src/state/urn.js
// Please modify with care and check if changes can be applied in Nora repository as well.

const Urn = {
  getType: (urn = '') => {
    if (typeof urn !== 'string') {
      return null;
    }

    const urnParts = urn.split(':').reverse();

    if (urnParts.length < 2) {
      return null;
    }

    if (typeof urnParts[1] !== 'string') {
      return null;
    }

    return urnParts[1];
  },
  getId: (urn = '') => {
    if (typeof urn !== 'string') {
      return null;
    }

    const urnParts = urn.split(':').reverse();

    if (urnParts.length < 2) {
      return null;
    }

    return urnParts[0];
  },
  getBusinessunit: (urn = '') => {
    if (typeof urn !== 'string') {
      return null;
    }

    const urnParts = urn.split(':');

    if (urnParts.length < 2) {
      return null;
    }

    return urnParts[1];
  },
  generate: (bu = 'srf', type, id) => {
    if (!type || !id) {
      return null;
    }

    return 'urn:' + bu + ':' + type + ':' + id;
  },
  isValid: (urn) => typeof urn === 'string' && urn.slice(0, 4) === 'urn:',
};

export default Urn;
