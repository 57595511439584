import LazyLoad from 'vanilla-lazyload';

export function init() {
  new LazyLoad({
    elements_selector: '.meteo-home-teaser-lazyload',

    unobserve_entered: true,
    callback_enter: () => {
      import(
        /* webpackChunkName: 'meteo-home-collection' */ `../../meteo/css/main-home.scss`
      )
        .then(() => {
          return import(
            /* webpackChunkName: 'meteo-home-collection' */ `../../meteo/js/srf-meteo-home.js`
          );
        })
        .then((meteoHomeCollection) => {
          meteoHomeCollection.init();
        });
    },
  });
}
