import React from 'react';
import { createRoot } from 'react-dom/client';

import { HtmlEmbed } from '@fec/frontend/modules/content/embed/HtmlEmbed';
import { PrivacyShield } from '@fec/frontend/modules/content/embed/PrivacyShield';
import { FacebookEmbed } from '@fec/frontend/modules/content/embed/FacebookEmbed';
import { TwitterEmbed } from '@fec/frontend/modules/content/embed/TwitterEmbed';
import { InstagramEmbed } from '@fec/frontend/modules/content/embed/InstagramEmbed';
import { ObsWidgetEmbed } from '@fec/frontend/modules/content/embed/ObsWidgetEmbed';

const EMBED_CONTAINER_CLASS = 'js-cmp-embed';

export const init = () => {
  Array.from(document.getElementsByClassName(EMBED_CONTAINER_CLASS)).forEach(
    (container) => {
      const embed = new cmpProtectedEmbed(container);
      embed.init();
    },
  );
};

class cmpProtectedEmbed {
  constructor(container) {
    this.container = container;
    this.type = 'undefined';
    this.embedParameter = null;
  }

  init = () => {
    const elementData = JSON.parse(this.container.dataset.cmpProtectedEmbed);
    this.type = elementData.type;
    this.title = elementData.title;
    this.styleModifier = elementData.styleModifier ?? null;
    this.contentStyleModifier = elementData.contentStyleModifier ?? null;

    switch (this.type) {
      case 'html':
        this.embed = <HtmlEmbed {...elementData}></HtmlEmbed>;
        break;
      case 'facebook':
        this.embed = <FacebookEmbed {...elementData}></FacebookEmbed>;
        break;
      case 'twitter':
        this.embed = <TwitterEmbed {...elementData}></TwitterEmbed>;
        break;
      case 'instagram':
        this.embed = <InstagramEmbed {...elementData}></InstagramEmbed>;
        break;
      case 'obsWidget':
        this.embed = <ObsWidgetEmbed {...elementData}></ObsWidgetEmbed>;
        break;
      default:
        return;
    }

    this.render();
  };
  render = () => {
    const root = createRoot(this.container);
    root.render(
      <PrivacyShield
        title={this.title}
        styleModifier={this.styleModifier}
        contentStyleModifier={this.contentStyleModifier}>
        {this.embed}
      </PrivacyShield>,
    );
  };
}
