/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicResultPage } from '../models/PublicResultPage';
import type { PublicThreadDto } from '../models/PublicThreadDto';
import type { ViewPublicComment } from '../models/ViewPublicComment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PublicThreadsService {
    /**
     * Get a single thread by URN
     * @returns PublicThreadDto Returned when successful
     * @throws ApiError
     */
    public static getApiPublicThreadInfo({
        bu,
        urn,
    }: {
        /**
         * businessUnit (srf|rtr)
         */
        bu: 'srf' | 'rtr',
        /**
         * Thread URN to fetch data for
         */
        urn: string,
    }): CancelablePromise<PublicThreadDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commentsapi/v1/public/{bu}/threads/{urn}',
            path: {
                'bu': bu,
                'urn': urn,
            },
            errors: {
                404: `Returned when thread was not found`,
            },
        });
    }
    /**
     * Returns public comments for a specific thread
     * @returns any Returned when successful
     * @throws ApiError
     */
    public static getApiPublicThreadComments({
        bu,
        urn,
        offset,
        nextToken,
        sort,
        limit,
    }: {
        /**
         * businessUnit (srf|rtr)
         */
        bu: 'srf' | 'rtr',
        /**
         * Thread URN to fetch comments data for
         */
        urn: string,
        /**
         * Offset
         */
        offset?: number,
        /**
         * Next token (created-timestamp)
         */
        nextToken?: number,
        /**
         * sort order (created|popular)
         */
        sort?: 'popular' | 'created',
        /**
         * Limit
         */
        limit?: number,
    }): CancelablePromise<{
        entries: Array<ViewPublicComment>;
        hasMoreResults: boolean;
        nextToken: number;
        moreResultsUrl: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commentsapi/v1/public/{bu}/threads/{urn}/comments',
            path: {
                'bu': bu,
                'urn': urn,
            },
            query: {
                'offset': offset,
                'nextToken': nextToken,
                'sort': sort,
                'limit': limit,
            },
            errors: {
                400: `Returned when wrong parameters given (e.g. wrong order)`,
                404: `Returned when thread was not found`,
            },
        });
    }
    /**
     * Returns stats for a specific thread
     * @returns any Returned when successful
     * @throws ApiError
     */
    public static getApiPublicThreadStats({
        bu,
        urn,
    }: {
        /**
         * businessUnit (srf|rtr)
         */
        bu: 'srf' | 'rtr',
        /**
         * Thread URN to fetch comments data for
         */
        urn: string,
    }): CancelablePromise<{
        urn?: string;
        commentsPublished?: number;
        threadState?: 'open' | 'closed' | 'new' | 'archived';
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commentsapi/v1/public/{bu}/threads/{urn}/stats',
            path: {
                'bu': bu,
                'urn': urn,
            },
            errors: {
                404: `Returned when one of the threads was not found`,
            },
        });
    }
    /**
     * Returns a collection of public threads
     * @returns PublicResultPage Returned when successful
     * @throws ApiError
     */
    public static getApiPublicThreads({
        bu,
        nextToken,
        limit,
        created,
        createdGt,
        createdGte,
        createdLt,
        createdLte,
        createdNeq,
        portal,
        portalIn,
        sort,
        state,
        stateIn,
    }: {
        /**
         * businessUnit (srf|rtr)
         */
        bu: 'srf' | 'rtr',
        /**
         * Next token (created-timestamp)
         */
        nextToken?: string,
        /**
         * Limit, max=25, default=25
         */
        limit?: number,
        /**
         * find records whose value of created equals given value<p>***Example***: 2024-06-24</p>
         */
        created?: string,
        /**
         * find records whose value of created is greater than given value<p>***Example***: 2024-06-24</p>
         */
        createdGt?: string,
        /**
         * <p>***Example***: 2024-06-24</p>
         */
        createdGte?: string,
        /**
         * find records whose value of created is lesser than given value<p>***Example***: 2024-06-24</p>
         */
        createdLt?: string,
        /**
         * <p>***Example***: 2024-06-24</p>
         */
        createdLte?: string,
        /**
         * find records whose value of created do not equal given value<p>***Example***: 2024-06-24</p>
         */
        createdNeq?: string,
        /**
         * find records whose value of portal equals given value<p>***Example***: news</p>
         */
        portal?: string,
        /**
         * Find records whose values of portal equals one of the given (comma-separated) set of values.<p>***Example***: news,sport</p>
         */
        portalIn?: string,
        /**
         * **Deprecation note**: field _thread.state_ in sort is ***deprecated***
         */
        sort?: 'thread.created' | '-thread.created' | 'thread.state' | '-thread.state',
        /**
         * find records whose value of state equals given value
         */
        state?: 'open' | 'closed',
        /**
         * Find records whose values of state equals one if the given (comma-separated) set of values. Allowed values: open,closed
         */
        stateIn?: string,
    }): CancelablePromise<PublicResultPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/commentsapi/v1/public/{bu}/threads',
            path: {
                'bu': bu,
            },
            query: {
                'nextToken': nextToken,
                'limit': limit,
                'created': created,
                'created__gt': createdGt,
                'created__gte': createdGte,
                'created__lt': createdLt,
                'created__lte': createdLte,
                'created__neq': createdNeq,
                'portal': portal,
                'portal__in': portalIn,
                'sort': sort,
                'state': state,
                'state__in': stateIn,
            },
        });
    }
}
