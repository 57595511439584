import DebounceHelper from '@fec/frontend/foundation/client/debounce-helper';

const THROTTLE_TIME = 420;

/**
 * Handles the tracking of the scroll depth.
 *
 * initial:
 * RLP + Article: send document height
 * Article: send article height
 *
 * on scroll, upon reaching 20, 40, 60, 70, 75, 80, 100%
 * RLP + Article: send scroll depth in %
 */
export class ScrollDepthMeasurement {
  constructor(pageType = 'Unbekannt', trackMe = () => {}) {
    this.pageType = pageType;
    this.sendData = trackMe;

    this.lastReachedPercentage = 0;
    this.targetPercentages = [20, 40, 60, 70, 75, 80, 100];
    // reverse array for quicker lookups later
    this.targetPercentagesReversed = [...this.targetPercentages].reverse();

    this.sendInitialMeasurements();

    // to not do the not insignificant amount of calculations in onScroll too
    // often, we debounce the method call. That means it is only called once
    // per X milliseconds and not on every scrolled pixel. The value of X can
    // be changed to get more up-to-date information but also need more
    // computing power.
    const throttledScrollHandler = DebounceHelper.throttle(
      () => this.onScroll(),
      THROTTLE_TIME,
    );

    // passive event listeners are much more performant, see
    // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#improving_scrolling_performance_with_passive_listeners
    document.addEventListener('scroll', () => throttledScrollHandler(), {
      passive: true,
    });
  }

  /**
   * on page load, UDP wants to know the total page height for both article
   * and landingpages.
   * For articles, they additionally want to know the height of the article
   * portion.
   *
   * And for the unlikely case that the screen is taller than the page or the
   * page is super tiny, we check for that and send the 100% scroll event
   * accordingly.
   */
  sendInitialMeasurements() {
    const currentPageHeight = document.documentElement.scrollHeight;

    // Send the height of the full page in px for articles and landingpages
    this.sendData({
      action_source: this.pageType,
      action_name: 'page_length',
      action_type: 'pixel_value',
      action_value: currentPageHeight,
    });

    if (this.pageType === 'Artikel') {
      const article = document.querySelector('.js-article');
      if (!article) {
        return;
      }

      const getTop = (el) => {
        return el.offsetTop + (el.offsetParent && getTop(el.offsetParent));
      };

      // Send the bottom edge of the article area in px for articles only
      this.sendData({
        action_source: this.pageType,
        action_name: 'article_length',
        action_type: 'pixel_value',
        action_value: getTop(article) + article.offsetHeight,
      });
    }

    // maybe there's already a percentage target visible
    this.onScroll();
  }

  /**
   * on each (debounced) scroll event, we check the scroll depth in percentage,
   * e.g. 69%. This value is compared to the last reached percentage
   * target, e.g. 20%. In this case, we see that the new percentage is larger,
   * which means the user scrolled down. Now we have to figure out if the user
   * reached a new scroll target in this direction.
   * It's not enough to just check if the user reached the next larger scroll
   * target because it's possible to skip some, so we go backwards from the
   * largest scroll target until we find one that is less than the current
   * scroll depth (i.e. was reached) but large than the one we reached before.
   * In this example that would be 60%, meaning we skipped/missed the 40%
   * event.
   *
   * For the visually inclined:
   *              +
   *              |
   * 20% +------> | <------+ last reached target
   *              |
   * 40% +------> | <------+ missed this target
   *              |
   * 60% +------> | <------+ next reached target
   *              | <------+ current scroll position, e.g. 69%
   * 70% +------> |
   * 75% +------> |
   * 80% +------> |
   *              |
   * 100% +-----> v
   *
   */
  onScroll() {
    const currentPercentage = this.getScrollPercent();

    let reachedPercentage;
    if (currentPercentage >= this.lastReachedPercentage) {
      // scrolled down: get the highest target that was reached but is still
      // larger than the last reached target
      reachedPercentage = this.targetPercentagesReversed.find(
        (val) => val <= currentPercentage && val > this.lastReachedPercentage,
      );
    } else {
      // scrolled up: get the lowest target that is higher than the current
      // position but lower than the last reached target
      reachedPercentage = this.targetPercentages.find(
        (val) => val >= currentPercentage && val < this.lastReachedPercentage,
      );
    }

    if (!reachedPercentage) {
      // no new target was reached
      return;
    }

    // save it to compare on the next scroll event
    this.lastReachedPercentage = reachedPercentage;
    // ... and send it!
    this.onScrollEvent(this.lastReachedPercentage);
  }

  onScrollEvent(percentage) {
    this.sendData({
      action_source: this.pageType,
      action_name: 'scroll',
      action_type: 'percentage_page',
      action_value: percentage,
    });
  }

  /**
   * Returns the lowest visible part of the page in the viewport in relation to
   * the whole page height.
   *
   * @returns {number}
   */
  getScrollPercent() {
    return (
      ((window.scrollY + window.innerHeight) / document.body.scrollHeight) * 100
    );
  }
}
