/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostReactionDto } from '../models/PostReactionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PublicCommentReactionsService {
    /**
     * Post a new comment reaction
     * @returns any Returned when created successfully
     * @throws ApiError
     */
    public static postApiPublicCommentReaction({
        urn,
        bu,
        requestBody,
    }: {
        /**
         * URN this reaction relates to
         */
        urn: string,
        /**
         * businessUnit (srf|rtr)
         */
        bu: 'srf' | 'rtr',
        /**
         * Input data format
         */
        requestBody?: PostReactionDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/commentsapi/v1/public/{bu}/comments/{urn}/reactions',
            path: {
                'urn': urn,
                'bu': bu,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Returned when already reacted to comment`,
                404: `Returned when comment was not found`,
                500: `Returned when reaction could not be saved`,
            },
        });
    }
}
