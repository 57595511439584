const IFRAME_CONTAINER_SELECTOR = '.js-modal-content-container';
const POSTMESSAGE_ACTION = 'srf.open-lightbox';
const IFRAME_HEIGHT = '600px';
const MODAL_CALLER = '.js-iframe-modal-caller';

export function init() {
  $(window).on('message', (event) => {
    if (
      /^https:\/\/(www|www-test|www-stage)\.srf\.ch$/.test(
        event.originalEvent.origin,
      )
    ) {
      let data = event.originalEvent.data;

      if (
        typeof data === 'object' &&
        data.action === POSTMESSAGE_ACTION &&
        data.href !== ''
      ) {
        $(IFRAME_CONTAINER_SELECTOR).find('iframe').remove();
        $(IFRAME_CONTAINER_SELECTOR).append(
          '<iframe style="height: ' +
            IFRAME_HEIGHT +
            '" frameborder="no" src="' +
            data.href +
            '"></iframe>',
        );

        /* The data-resize-height identifier is already used as an identifier for resizing the iframe. */
        if (typeof data.chart !== 'undefined') {
          let $target = $('[data-resize-height="' + data.chart + '"]');

          /* We open the modal by clicking on a fake link. It's important to have a caller, so the modal may set the focus back to the caller after closing. */
          const $insideModalCaller = $target
            .parent()
            .parent()
            .find(MODAL_CALLER);
          if ($insideModalCaller.length > 0) {
            $insideModalCaller.click();
          } else {
            $(MODAL_CALLER).click();
          }
        }
      }
    }
  });
}
