import { SRFBridge } from 'srf-native-bridge';

export class DownloadMeasurement {
  constructor(webtrekk) {
    this.initObserver(webtrekk);
  }

  initObserver(webtrekk) {
    $('body').on('click', '[data-download-event-track]', (event) => {
      let $element = $(event.currentTarget);

      event.preventDefault();
      if (SRFBridge.isRunningInApp()) {
        let payload = {
          webtrekk: {
            event_type: 'click',
            event_source: 'file_download',
            event_name: $element.data('download-event-track'),
            event_value: $element.attr('href'),
          },
        };

        SRFBridge.trackEvent(payload);
      } else if (webtrekk) {
        webtrekk.sendInfo({
          linkId: 'click',
          customClickParameter: {
            1: 'file_download',
            3: $element.data('download-event-track'),
            4: $element.attr('href'),
          },
        });
      }

      window.location = $element.attr('href');
    });
  }
}
