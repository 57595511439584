import moment from 'moment';

import ClientStorage from '@fec/frontend/foundation/client/storage';
import { TEASER_CLICKED, triggerEvent } from '@fec/assets/js/utils/event';
import { readUrnFromMetaTag } from '@fec/assets/js/utils/meta-tags';
import Urn from '@fec/frontend/foundation/client/urn';

export const init = () => {
  let teaserStatus = new TeaserInfoUpdater();
  teaserStatus.init();
};

export const STORAGE_KEY = 'srf:item:seen:status';
const MAX_ENTRIES = 150; // for how many entries should the status visited/seen be saved?

export class TeaserInfoUpdater {
  init() {
    // A click on a teaser should mark the urn of the entity "behind" the
    // teaser as seen and save it to the localstorage for later retrieval.
    this.bindTeaserClickHandlers();

    if (Urn.getType(readUrnFromMetaTag()) === 'article') {
      // When visiting an article it only has to be marked as "seen", so that
      // upon visiting a page where a teaser for this article is placed, it can
      // be marked as visited.
      // This is done because not all visits to an article happen via click on
      // a teaser, users can also land on an article by following a link from
      // external sources (search engines, social media, bookmarks, ...).
      this.markItemAsSeen();
    }
  }

  // FiFo tracking of articles, videos and audios (in general everything with an urn)
  markItemAsSeen(urn) {
    const itemUrn = urn
      ? urn
      : $('.js-article').find('[itemprop=identifier]').prop('content');
    if (!itemUrn) {
      return;
    }
    // keep the queue at limits
    let list = this.getSeenItems().slice(-MAX_ENTRIES);

    // remove view entry for same article (to update the timestamp later on)
    list = list.filter((entry) => entry.urn !== itemUrn);

    // add current article view event to queue
    list.push({
      urn: itemUrn,
      timestamp: moment().format(),
    });

    // persist
    ClientStorage.setItemJsonStringified(STORAGE_KEY, list);
  }

  getSeenItems() {
    return ClientStorage.getItemJsonParsed(STORAGE_KEY, []);
  }

  isTeaserLink(event) {
    // clicking on a teaser link does not count as clicking on a teaser!
    return event.target.classList.contains('js-teaser-ng-link');
  }

  bindTeaserClickHandlers() {
    // This handler will track seen articles, videos and audios directly on click
    $('body').on('mouseup', '.js-teaser-ng', (event) => {
      const $item = $(event.currentTarget);
      const urn = $item.data('urn');

      if (this.isTeaserLink(event)) {
        return;
      }

      if ($item.data('track-urn')) {
        this.markItemAsSeen($item.data('track-urn'));
      }

      if (urn) {
        this.markItemAsSeen(urn);
        // Videos and Audios open on page, we can display them instantly as seen
        if (urn.includes(':video:') || urn.includes(':audio:')) {
          triggerEvent(TEASER_CLICKED);
        }
      }
    });

    $('body').on('click', '.js-teaser-ng', (event) => {
      if (this.isTeaserLink(event)) {
        return;
      }

      setTimeout(() => triggerEvent(TEASER_CLICKED), 1500);
    });
  }
}
