import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from '@fec/frontend/foundation/translations/de/translation.json';
import rm from '@fec/frontend/foundation/translations/rm/translation.json';

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      de: {
        translation: de,
      },
      rm: {
        translation: rm,
      },
    },
    lng: document.documentElement.lang,
    fallbackLng: 'de',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
