import { v4 as uuidv4 } from 'uuid';
import { onEvent, TRACK_UDP_ACTION } from '@fec/assets/js/utils/event';
import { ScrollDepthMeasurement } from './ScrollDepthMeasurement';
import { SRFBridge } from 'srf-native-bridge';

export class UDPTracking {
  constructor(CONFIG = {}, tools) {
    this.trackingHost = CONFIG.ANALYTICS_UDP_ENDPOINTS.tracking_host;
    this.tools = tools;
    this.uuidName = 'srf:analytics:uuid';
    const modData = this.getModValues();
    this.page_view = Object.assign(CONFIG.ANALYTICS_UDP_VALUE_OBJECT || {}, {
      timestamp: Date.now(),
      cookie_id: this.getOrCreateUUID(),
      url: window.location.href,
      referrer: document.referrer,
      navigation_path_srg_mod_1: modData
        ? this.tools.getModData(modData, 'mod1')
        : null,
      navigation_path_srg_mod_2: modData
        ? this.tools.getModData(modData, 'mod2')
        : null,
      navigation_path_srg_mod_3: modData
        ? this.tools.getModData(modData, 'mod3')
        : null,
      navigation_path_srg_mod_4: modData
        ? this.tools.getModData(modData, 'mod4')
        : null,
      user_settings: window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark_mode(1)'
        : 'dark_mode(0)',
      user_agent: navigator.userAgent,
      screen_resolution:
        window.screen.availWidth + 'x' + window.screen.availHeight,
      language: navigator.language,
    });

    // how long this website has been open in seconds
    this.timeoutsForKeepAlive = [15, 30, 60, 150, 300, 600, 900, 1200];

    if (SRFBridge.isRunningInApp()) {
      this.initAppObserver();
    } else {
      this.initObserver();
    }
    this.handleExperiments();
  }

  getModValues() {
    let modData = JSON.parse(this.tools.getData('srf:analytics:mod:udp'));
    this.tools.removeData('srf:analytics:mod:udp');
    return modData;
  }

  getOrCreateUUID() {
    // user ID is stored in local storage and cookie
    // get uuid from either of these
    let uuid_ls = this.tools.getData(this.uuidName);
    uuid_ls = this.isUUID(uuid_ls) ? uuid_ls : null;
    const uuid_cookie = this.getUUIDFromCookie();
    let uuid = uuid_cookie === null ? uuid_ls : uuid_cookie;
    if (uuid === null) {
      // if both are empty, create new uuid
      uuid = this.createUUID();
    }

    // populate uuid
    this.tools.setData(this.uuidName, uuid);
    this.extendUUIDCookieLifetime(uuid);
    return uuid;
  }

  isUUID(inputString) {
    return inputString === null ? false : inputString.match(/^[a-f0-9-]{36}$/);
  }

  getUUIDFromCookie() {
    const matches = document.cookie.match(
      new RegExp(`${this.uuidName}=([a-f0-9\\-]{36})`),
    );
    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      return null;
    }
  }

  createUUID() {
    return uuidv4();
  }

  extendUUIDCookieLifetime(uuid) {
    let d = new Date();
    // cookie is valid 2 years
    d.setTime(d.getTime() + 2 * 365 * 24 * 60 * 60 * 1000);
    document.cookie = `${
      this.uuidName
    }=${uuid};expires=${d.toUTCString()};samesite=strict;path=/`;
  }

  sendData(data) {
    fetch(`${this.trackingHost}/tracking/pageview`, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.warn('Error:', error);
    });
  }

  /**
   * Custom data wrangling for scroll depth tracking according to
   * https://confluence.srg.beecollaboration.com/pages/viewpage.action?pageId=87525617
   *
   * @param scrollData
   */
  trackScrollEvent(scrollData) {
    const pv = this.page_view;
    const data = Object.assign(
      {
        schema_version: pv.schema_version,
        type: 'ui_event',
        product: pv.product,
        backend_system: pv.backend_system,
        business_unit: pv.business_unit,
        timestamp: Date.now(),
        cookie_id: pv.cookie_id,
        url: pv.url,
        page_urn: pv.page_urn,
        content_title_pretty: pv.content_title_pretty,
        content_publication_datetime: pv.content_publication_datetime,
        content_category_1: pv.content_category_1,
      },
      scrollData,
    );

    this.sendUiEvent(data);
  }

  initAppObserver() {
    if (this.page_view.content_page_type === 'Artikel') {
      new ScrollDepthMeasurement(this.page_view.content_page_type, (data) => {
        SRFBridge.trackUdpEvent({
          actionSource: data.action_source.toString(),
          actionName: data.action_name.toString(),
          actionType: data.action_type.toString(),
          actionValue: data.action_value.toString(),
        });
      });
    }
  }

  initObserver() {
    this.timeoutsForKeepAlive.forEach((timeout) => {
      let actionEventData = {
        action_source: this.page_view.content_page_type,
        action_name: 'keep_alive',
        action_type: 'second_value',
        action_value: timeout,
        type: 'inactive_event',
      };
      setTimeout(() => this.trackEvent(actionEventData), timeout * 1000);
    });

    new ScrollDepthMeasurement(this.page_view.content_page_type, (data) =>
      this.trackScrollEvent(data),
    );

    document.addEventListener('click', (event) => {
      if (event?.target?.getAttribute('data-udp-action-track') !== null) {
        // e.g. <button data-udp-action-track="action_source=radio&action_type=click&action_element=button&action_name=Musik&action_location=last_played"></button>
        const element = event.target;
        // e.g. "action_source=radio&action_type=click&action_element=button&action_name=Musik&action_location=last_played"
        const rawTrackingParams = element.getAttribute('data-udp-action-track');
        const trackingParams = new URLSearchParams(rawTrackingParams);
        // {action_element: "button", action_location: "last_played, "action_name: "Musik", action_source: "radio", action_type: "click"}
        const trackingObject = Object.fromEntries(trackingParams);

        this.trackEvent(trackingObject);
      }
    });

    onEvent({
      eventName: TRACK_UDP_ACTION,
      eventHandler: ({ detail }) => this.trackEvent(Object.assign({}, detail)),
    });
  }

  trackEvent(eventData = {}) {
    const pv = this.page_view;
    const payload = Object.assign(
      {
        schema_version: pv.schema_version,
        type: 'action_event',
        product: pv.product,
        backend_system: pv.backend_system,
        business_unit: pv.business_unit,
        timestamp: Date.now(),
        cookie_id: pv.cookie_id,
        url: pv.url,
        page_urn: pv.page_urn,
        content_title_pretty: pv.content_title_pretty,
        content_publication_datetime: pv.content_publication_datetime,
        content_category_1: pv.content_category_1,
      },
      eventData,
    );

    this.sendUiEvent(payload);
  }

  sendUiEvent(payload = {}) {
    fetch(`${this.trackingHost}/tracking/uievent`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.warn('Error:', error);
    });
  }

  /**
   * Send all handled experiments to UDP for tracking
   */
  handleExperiments() {
    const STORAGE_KEY = 'srf:experiments:loaded';

    const loadedExperiments = this.tools.getData(STORAGE_KEY);

    if (loadedExperiments) {
      const experimentsData = Object.values(JSON.parse(loadedExperiments));

      experimentsData.forEach((data) => {
        if (SRFBridge.isRunningInApp()) {
          SRFBridge.trackUdpEvent({
            actionSource: data.experimentUrn,
            actionName: 'experiment',
            actionType: 'testgroup',
            actionValue: data.variationUrn,
          });
        } else {
          this.trackEvent({
            action_source: data.experimentUrn,
            action_name: 'experiment',
            action_type: 'testgroup',
            action_value: data.variationUrn,
            type: 'ui_event',
          });
        }
      });
    }

    // remove the data from localstorage again
    this.tools.removeData(STORAGE_KEY);
  }
}
