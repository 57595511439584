import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './button.scss';

const Button = React.forwardRef(
  (
    {
      styleModifier,
      className,
      additionalAttributes = {},
      href,
      target,
      type,
      svgIcon,
      screenReaderText,
      buttonText,
      buttonTextMobile,
      onClick = () => {},
      tag,
    },
    ref,
  ) => {
    const Tag = tag ?? (href ? 'a' : 'button');

    let screenReaderTextToRender = 'Button';
    if (screenReaderText) {
      screenReaderTextToRender = screenReaderText;
    } else if (buttonText) {
      screenReaderTextToRender = buttonText;
    } else if (buttonTextMobile) {
      screenReaderTextToRender = buttonTextMobile;
    }

    const validType = Tag === 'a' ? type : validButtonType(type);

    return (
      <Tag
        className={classNames('button', styleModifier, className)}
        href={href}
        target={target}
        {...additionalAttributes}
        onClick={onClick}
        ref={ref}
        type={validType}>
        <span className="h-offscreen">{screenReaderTextToRender}</span>
        <span
          className={classNames('button-wrapper', {
            'button-wrapper--svg-icon': svgIcon,
          })}
          aria-hidden="true"
          role="presentation">
          {svgIcon && (
            <span className="button-icon">
              <span className={classNames('svg-icon', svgIcon)} />
            </span>
          )}
          {buttonTextMobile && (
            <span className="button-text button-text--mobile">
              {buttonTextMobile}
            </span>
          )}
          {buttonText && <span className="button-text">{buttonText}</span>}
        </span>
      </Tag>
    );
  },
);

// W3C Validator
const validButtonType = (type) => {
  const VALID_BUTTON_TYPES = ['submit', 'reset', 'button'];

  if (VALID_BUTTON_TYPES.includes(type)) {
    return type;
  }
  return 'button';
};

Button.propTypes = {
  styleModifier: PropTypes.string,
  className: PropTypes.string,
  additionalAttributes: PropTypes.object,
  href: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  svgIcon: PropTypes.string,
  screenReaderText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTextMobile: PropTypes.string,
  onClick: PropTypes.func,
  tag: PropTypes.string,
};

export default Button;
