import React from 'react';
import classNames from 'classnames';

import './svg-icon.scss';

export const SvgIcon = ({ styleModifier }) => (
  <span className={classNames('svg-icon', styleModifier)} />
);

export default SvgIcon;
