import 'iframe-resizer';
import { ResizeListener } from '@fec/frontend/foundation/client/resize-listener';
import { CMP_PRIVACY_SHIELD_LOADED, onEvent } from '@fec/assets/js/utils/event';
/* global iFrameResize */

export function init() {
  // scale by initial ratio as default case
  adjustIframesByRatio();
  ResizeListener.subscribeThrottled(() => adjustIframesByRatio());

  //handle data wrapper
  window.addEventListener('message', function (e) {
    if (void 0 !== e.data['datawrapper-height']) {
      let t = document.querySelectorAll('iframe');
      for (let a in e.data['datawrapper-height']) {
        for (let r = 0; r < t.length; r++) {
          if (t[r].contentWindow === e.source) {
            t[r].style.height = e.data['datawrapper-height'][a] + 'px';
          }
        }
      }
      e.stopPropagation();
      e.preventDefault();
    }
  });

  // some iframe windows might send their own height in a specific payload
  $(window).on('message', (event) => {
    adjustIframeByFixedHeight(event);
  });
  triggerIframeResizeCommand();

  onEvent({
    eventName: CMP_PRIVACY_SHIELD_LOADED,
    eventHandler: triggerIframeResizeCommand,
  });

  const $deeplinkableIframe = $('.js-deeplinkable-iframe');
  if ($deeplinkableIframe.length && $deeplinkableIframe.data('deeplink-base')) {
    let params = new URL(document.location).searchParams;
    if (params.has('deeplink')) {
      try {
        const deeplink = new URL(
          params.get('deeplink'),
          $deeplinkableIframe.data('deeplink-base'),
        );
        const deeplinkBase = new URL($deeplinkableIframe.data('deeplink-base'));
        //check for url manipulation and force the deeplink base host
        if (deeplinkBase.host === deeplink.host) {
          $deeplinkableIframe.attr('src', deeplink);
        }
      } catch (e) {
        //do not update frame source if this is not a valid link
      }
    }
  }
}

function triggerIframeResizeCommand() {
  // send initial postMessage to inform iframes we are ready
  $('.iframe-container iframe, .js-iframe-resize').on('load', (event) => {
    let $currentTarget = $(event.currentTarget);

    $currentTarget[0].contentWindow.postMessage(
      {
        action: 'srf.resize-iframe',
        ready: true,
      },
      '*',
    );

    if (typeof window.iFrameResize === 'function') {
      let calcMethod =
        $currentTarget.attr('data-iframe-resize-calc-method') || 'bodyOffset';
      if ($currentTarget.is('.js-deeplinkable-iframe')) {
        calcMethod = 'taggedElement';
      }

      iFrameResize(
        {
          warningTimeout: 0,
          autoResize: true,
          heightCalculationMethod: calcMethod,
          onInit: (iframe) => {
            $(iframe)
              .closest('.iframe-container')
              .removeClass('iframe-container__noresizeable');
          },
          onResized: (info) => {
            if (info.iframe.getAttribute('data-scroll-to-after-resize')) {
              scrollTo(
                0,
                info.iframe.offsetTop + info.iframe.offsetParent.offsetTop,
              );
            }
          },
        },
        `#${$currentTarget.attr('id')}`,
      );
    }
  });
}

function adjustIframeByFixedHeight(event) {
  let data = event.originalEvent.data;
  if (typeof data === 'object') {
    // fallbacks for legacy reasons
    if (typeof data.action === 'undefined') {
      data.action = 'srf.resize-iframe';
    }

    if (data.action === 'srf.resize-iframe') {
      let $target = false;
      // try to find the iframe to resize by its name (EXACT MATCH, best case)
      // the name will be submitted in the payload and has to be entered in the editorial interface
      // as a data-resize-height-attribute
      if (typeof data.resizeTarget !== 'undefined') {
        let $possibleExactTarget = $(
          '[data-resize-height="' + data.resizeTarget + '"]',
        );
        if ($possibleExactTarget.length) {
          $target = $possibleExactTarget;
        }
      }

      // no exact match possible, lets try to find a fitting iframe
      if (!$target) {
        // in the second best case, we have exactly one iframe on the page
        // this has to be our target, right?
        $target = $('.iframe-container iframe');

        // if we have several iframes, we could try to peek into its contentWindow
        // but only, if it comes from the same domain, so this is not safe by any means
        if ($target.length > 1) {
          $target.each((i, el) => {
            // yay, this is the iframe that sent us its height
            if (el.contentWindow === event.source) {
              $target = $(el);
            }
          });
        }
      }

      if ($target.length > 0) {
        $target.each((i, el) => {
          // sends postMessage to iframe to confirm the receive of the postMessage
          el.contentWindow.postMessage({ received: data.height }, '*');

          // some legacy iframes don't send a height but a rectangle
          if (
            typeof data.height === 'undefined' &&
            typeof data.bounds !== 'undefined'
          ) {
            data.height = data.bounds.height;
          }

          // in case of mobile breakpoint the iframe is by default not displayed. that's why the iframe can't identify it's height and sends zero
          // if we get zero, we know the iframe is willing to send it's height, so we change the display to block.
          // because the iframe is now displayed, the height changes and the iframe sends now the new correct height and all works just fine...
          let targetHeight = parseInt(data.height, 10);
          if (!isNaN(targetHeight) && targetHeight > 0) {
            $(el).height(targetHeight);
            // mark the iframe's container so we don't scale it with the fallback adjustIframesByRatio()
            // and by the way, this way around, it won't be hidden on mobile sizes
            $(el)
              .closest('.iframe-container')
              .removeClass('iframe-container__noresizeable');
          } else {
            $(el).css('display', 'block');
          }
        });
      }
    }
  }
}

function adjustIframesByRatio() {
  $(
    '.iframe-container.iframe-container__noresizeable iframe, ' +
      '.embed-inline .embed-inline__content iframe',
  ).each((index, iframe) => {
    let $iframe = $(iframe),
      proportion = $iframe.data('proportion'),
      w = $iframe.attr('width'),
      actual_w = $iframe.width();
    if (!proportion) {
      proportion = $iframe.attr('height') / w;
      $iframe.data('proportion', proportion);
    }

    $iframe.css('height', Math.round(actual_w * proportion) + 'px');
  });
}
