/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ViewPublicComment } from '../models/ViewPublicComment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommentsService {
    /**
     * Post a new comment
     * @returns ViewPublicComment Returned when created successfully
     * @throws ApiError
     */
    public static postPublicPostComment({
        bu,
        requestBody,
    }: {
        /**
         * businessUnit (srf|rtr)
         */
        bu: 'srf' | 'rtr',
        /**
         * Input data format
         */
        requestBody?: {
            /**
             * Body text of new comment, maxlength=600
             */
            text?: string;
            /**
             * urn of thread
             */
            thread?: string;
            /**
             * name of author (can only by set by moderators)
             */
            authorName?: string;
            /**
             * nickname of the author (can only by set by moderators)
             */
            authorUsername?: string;
        },
    }): CancelablePromise<ViewPublicComment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/commentsapi/v1/{bu}/comments',
            path: {
                'bu': bu,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Returned when thread was not found`,
            },
        });
    }
    /**
     * Post a new reply
     * @returns ViewPublicComment 201
     * @throws ApiError
     */
    public static postPublicPostReply({
        urn,
        bu,
        requestBody,
    }: {
        /**
         * URN this new comment replies to
         */
        urn: string,
        /**
         * businessUnit (srf|rtr)
         */
        bu: 'srf' | 'rtr',
        /**
         * Input data format
         */
        requestBody?: {
            /**
             * Body text of new comment, maxlength=600
             */
            text?: string;
            /**
             * name of the author (can only by set by moderators)
             */
            authorName?: string;
            /**
             * nickname of the author (can only by set by moderators)
             */
            authorUsername?: string;
        },
    }): CancelablePromise<ViewPublicComment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/commentsapi/v1/{bu}/comments/{urn}/replies',
            path: {
                'urn': urn,
                'bu': bu,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Returned when parent comment was not found`,
            },
        });
    }
}
