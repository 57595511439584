import { ResponsiveHelper } from '@fec/frontend/foundation/client/responsive-helper';
import { BouncePrevention } from '@fec/frontend/foundation/client/bounce-prevention';
import { KEYCODES } from '@fec/frontend/foundation/utils/keycodes';
import { setFocus } from '@fec/frontend/foundation/a11y';
import {
  MODAL_CLOSED,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_META_THEME_COLOR,
  SHOW_HIDDEN_TEASER,
  triggerEvent,
  onEvent,
} from '@fec/assets/js/utils/event';
import { canShareNatively } from '@fec/frontend/snowflakes/radio/radio-action-bar/radio-action-bar';

const ANIMATION_FADE_IN_OUT = 'fade-in-out';
const ANIMATION_SCALE_FROM_ORIGIN = 'scale-from-origin';
const ANIMATION_FLYOUT = 'as-flyout-from-origin';
const ANIMATION_SLIDE_FROM_BOTTOM = 'slide-from-bottom';

const ANIMATION_SPEED = window.matchMedia('(prefers-reduced-motion)').matches
  ? 0
  : 200;
const END_OF_MODAL = '.js-end-of-modal';

// Making sure that no modal is loaded twice
let existingModals = {};

/**
 * Clicks are listened to via the document, so we can control when we want to
 * start listening (some listeners have to be started before this one).
 */
export function init() {
  const onTrigger = (modalId, $caller) => {
    // special case: if native sharing is supported and the modal would be a
    // sharing modal, we do not open the modal.
    // Controlled via data-modal-yield-to-native-share
    if (
      $caller &&
      typeof $caller[0]?.dataset?.modalYieldToNativeShare !== 'undefined' &&
      canShareNatively()
    ) {
      return;
    }

    // make sure there's actually a modal element with that ID
    const $modalElement = $(`[data-id=${modalId}]`);

    // "save" the modal if it hasn't been loaded before
    if (existingModals[modalId]) {
      existingModals[modalId].setCaller($caller);
      existingModals[modalId].postInit();
    } else if ($modalElement.length > 0) {
      existingModals[modalId] = new Modal(modalId, $modalElement, $caller);
    }
  };

  // modals can be opened programmatically via events
  onEvent({
    eventName: OPEN_MODAL,
    eventHandler: ({ detail }) => onTrigger(detail.modalId, $(detail.caller)),
  });

  // or via click listeners
  $(document).on('click', '[data-modal-id]', (event) => {
    event.preventDefault();

    const $caller = $(event.currentTarget);
    const modalId = $caller.attr('data-modal-id');

    onTrigger(modalId, $caller);
  });
}

/**
 * Handles showing and hiding a modal element
 */
export class Modal {
  /**
   * @param modalId string
   * @param $element jQuery element
   * @param $caller jQuery element
   */
  constructor(modalId, $element, $caller) {
    this.modalId = modalId;
    this.$element = $element;
    this.setCaller($caller);
    this.$focusTarget = this.$element.find('.js-focus-target').first();
    this.$mainWrapper = this.$element.find('.js-modal-main-wrapper');
    this.$mainContent = this.$element.find('.js-modal-main-content');
    this.animation = this.$element.attr('data-animation');
    this.previousScrollPosition = null;
    this.browserSupportsElasticScrolling = BouncePrevention.checkSupport();

    // Accessibility: when opening the modal, set all other content on the page to aria-hidden, so that screenreaders can't access them anymore.
    this.$A11YElements = this.$element
      .siblings('main, div, section, footer, span, h1, a, img')
      .not('[aria-hidden=true]'); // don't touch elements that have aria-hidden=true already set!

    $element.append('<a class="js-end-of-modal h-offscreen" href="#"></a>');
    this.$mainWrapper.append(
      '<a class="js-close-modal h-offscreen h-offscreen-focusable h-offscreen-focusable--top" href="#">Schliessen</a>',
    );

    // If the modal is inside a different modal, things get weird.
    if (this.$element.parents('[data-id]').length > 0) {
      let parentModalId = this.$element.parents('[data-id]').data('id');
      // sanity check: data-id is so general, make sure it's actually a modal
      // (i.e. has an entry in existingModals)
      let parentModal = existingModals[parentModalId];

      if (parentModal) {
        // this modal is inside another modal.
        this.parentModal = parentModal;
      }
    }

    this.bindEvents();

    if (this.$element.hasClass('js-min-height-of-masthead')) {
      this.$mainContent.css('min-height', $('.js-masthead').outerHeight());
    }

    this.postInit();
  }

  postInit() {
    // show modal upon creation. Overridden in SrfVideoModal where the modal is not opened directly
    this.show();
  }

  /**
   * Binds the relevant events for this modal:
   * - Click on a close-button or the overlay
   * - Pressing Escape
   */
  bindEvents() {
    this.$element
      .find('.js-close-modal, .js-modal-overlay')
      .on('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        triggerEvent(MODAL_CLOSED, { modalId: this.modalId });
        this.close();
      });

    this.$element.on('keydown', (e) => {
      if (e.keyCode === KEYCODES.escape) {
        this.close();
      }
    });

    // A11Y Helper: when tabbing out of the modal --> on focus, close modal, set focus to the caller
    $(END_OF_MODAL).on('focus', () => {
      this.close();
    });

    onEvent({
      eventName: CLOSE_MODAL,
      eventHandler: ({ detail }) => {
        if (detail?.modalId === this.modalId) {
          this.close();
        }
      },
    });
  }

  /**
   * Show the modal, depending on the provided animation.
   * The actual _showing_ of the modal is done by jQuery ($.show() or $.fadeIn()).
   */
  show(modalBGColor = '') {
    if (this.$caller?.length > 0) {
      this.$caller.attr({ 'aria-expanded': true, 'aria-haspopup': true });
    }

    // When the flyout will be opened with a corresponding Modal-BG-Color (to
    // change the meta theme-color), the event will be triggered directly. If
    // there is no Modal-BG-Color available yet, this script will go and fetch
    // it and trigger the meta-theme-color-change-event.
    if (modalBGColor === '') {
      modalBGColor = window
        .getComputedStyle(document.querySelector('.modal'))
        .getPropertyValue('--t-modal-bg');
    }

    triggerEvent(SET_META_THEME_COLOR, modalBGColor);

    // When a flyout is opened from within a modal and the flyout is taller
    // than the parent, the parent should not hide the overflow (i.e. the
    // flyout). This only happens on mobile.
    if (ResponsiveHelper.isMobile() && this.parentModal) {
      this.parentModal.$mainWrapper.css('overflow', 'visible');
    }

    switch (this.animation) {
      case ANIMATION_SCALE_FROM_ORIGIN:
        this.scaleFromOrigin(() => this.onShowFinished());
        break;
      case ANIMATION_FLYOUT:
        this.asFlyoutFromOrigin(() => this.onShowFinished());
        break;
      case ANIMATION_FADE_IN_OUT:
        this.$element
          .stop(true, true)
          .fadeIn(ANIMATION_SPEED, () => this.onShowFinished());
        break;
      case ANIMATION_SLIDE_FROM_BOTTOM:
        this.slideFromBottom(() => this.onShowFinished());
        break;
      default:
        this.$element.show(() => this.onShowFinished());
        break;
    }
  }

  onShowFinished() {
    if (this.shouldPreventScrolling()) {
      this.preventScrolling();
    }

    if (this.animation !== ANIMATION_FLYOUT) {
      this.setA11YProperties(true);
    }

    if (this.$focusTarget && this.$focusTarget.length === 1) {
      setFocus(this.$focusTarget);
    }

    // some modals can contain other teasers, then we need to make sure their images are loaded
    this.$element.find('.js-teaser-ng').each((_, teaserElement) => {
      triggerEvent(SHOW_HIDDEN_TEASER, teaserElement);
    });
  }

  /**
   * Hide the modal, depending on the provided animation.
   */
  close() {
    this.scrollToPreviousPosition();

    triggerEvent(SET_META_THEME_COLOR);

    if (this.$caller?.length > 0) {
      this.$caller.attr({ 'aria-expanded': false, 'aria-haspopup': false });
    }

    if (ResponsiveHelper.isMobile() && this.parentModal) {
      this.parentModal.$mainWrapper.css('overflow', '');
    }

    switch (this.animation) {
      case ANIMATION_FADE_IN_OUT:
        this.setA11YProperties(false);
        this.$element
          .stop(true, true)
          .fadeOut(ANIMATION_SPEED, () => this.focusCaller());
        break;
      case ANIMATION_FLYOUT:
        this.$element.hide(0, '', () => this.focusCaller());
        break;
      case ANIMATION_SLIDE_FROM_BOTTOM:
        this.setA11YProperties(false);
        this.slideFromBottomClose();
        break;
      default:
        this.setA11YProperties(false);
        this.$element.hide(ANIMATION_SPEED, '', () => this.focusCaller());
        break;
    }
  }

  /**
   * Fancy menu opening animation:
   * - fades the modal in
   * - 'opens' it from the originating element
   * - fades in the content (otherwise it'll be resized)
   * - calls an optional callback
   *
   * For aesthetic reasons we have to animate to the previous height and not 100% max-height directly.
   */
  scaleFromOrigin(callBack) {
    this.$mainContent.css('opacity', 0);
    this.$element.show();

    let originalHeight = this.$mainWrapper.height();
    let box = { height: 0, width: 0, x: 0, y: 0 };
    if (this.$caller?.length > 0) {
      box = this.$caller[0].getBoundingClientRect();
    }
    this.$mainWrapper
      .css({
        left: box.left,
        width: box.width,
        'max-height': box.height,
        top: box.top,
        opacity: 0,
      })
      .animate(
        {
          left: 0,
          width: '100%',
          'max-height': originalHeight,
          top: 0,
          opacity: 1,
        },
        ANIMATION_SPEED,
        'easeInOutSine',
        () => {
          // remove the scrollbars
          const scrollbarWidth =
            window.innerWidth - document.documentElement.clientWidth;
          this.$mainWrapper.css({
            'max-height': '100%',
            width: `calc(100% + ${scrollbarWidth}px)`,
            'margin-right': scrollbarWidth,
          });
          this.$mainContent.animate(
            {
              opacity: 1,
            },
            ANIMATION_SPEED,
            callBack,
          );
        },
      );
  }

  /**
   * Flyout opening animation:
   * - used for flyout-modals
   * - mobile: flyout is fixed to the bottom of the viewport
   * - tablet-up: flyout is top aligned to and horizontally centered over the caller element (i.e. a button)
   */
  asFlyoutFromOrigin(callBack) {
    // clear existing inline styles on flyout (in case a resizing of the viewport happened)
    this.$element.attr('style', '');

    this.$element.css({
      display: 'block',
      opacity: 0,
    });

    if (!ResponsiveHelper.isMobile()) {
      // a flyout can be placed anywhere in the dom. but for positioning it relative to the caller (while staying
      // at place on scrolling), it must be positioned absolutely relative to the page. that's why we move it in
      // the DOM to be a first-level child of the body element, if needed.
      if (this.$element.parent().get(0).tagName !== 'BODY') {
        $('body').append(this.$element);
      }

      /*
       * Flyout position absolute: top position relative to the document (flyout scrolls with content)
       */
      let position = 'absolute';

      let callerBox = this.$caller.offset();
      let flyoutBox = this.$element[0].getBoundingClientRect();

      let newPosLeft = Math.ceil(
        callerBox.left + this.$caller.outerWidth() / 2 - flyoutBox.width / 2,
      );

      // open flyout downwards
      let newPosTop = callerBox.top;
      const callerHeight = this.$caller[0].getBoundingClientRect().height;

      /*
       * Flyout position absolute: top position fix = flyout fix on screen
       */
      if (this.$element.attr('data-css-fixed-position')) {
        position = 'fixed';

        // open flyout centered
        newPosTop = Math.ceil(
          this.$caller[0].getBoundingClientRect().top +
            callerHeight / 2 -
            flyoutBox.height / 2,
        );

        // open flyout upwards
        if (newPosTop + flyoutBox.height > window.innerHeight) {
          newPosTop =
            this.$caller[0].getBoundingClientRect().top +
            callerHeight -
            flyoutBox.height;
        }
      } else if (this.$element.attr('data-place-modal-below-caller')) {
        newPosTop = callerBox.top + callerHeight;
      }

      this.$element.css({
        position: position,
        left: newPosLeft + 'px',
        top: newPosTop + 'px',
      });
    }

    this.$element.animate(
      {
        opacity: 1,
      },
      ANIMATION_SPEED,
      'easeInOutSine',
      callBack,
    );
  }

  /**
   * modal opening animation:
   * - slides the modal in from the bottom
   * - adjusts the animation speed depending on modal height
   * - calls an optional callback
   */
  slideFromBottom(callBack = () => {}) {
    this.$element.show();
    let modalHeight = this.$mainWrapper.outerHeight();
    let animationSpeed =
      ANIMATION_SPEED > 0
        ? ANIMATION_SPEED + Math.floor(modalHeight / 100) * 25
        : 0; // adjusting animation speed

    // bind listener for transitionend to invoke callback after transition ended
    this.$mainWrapper.one('transitionend', () => callBack());

    this.$mainWrapper.css({
      bottom: `-${modalHeight}px`,
      transition: `transform ${animationSpeed}ms ease-in-out`,
      transform: `translateY(-${modalHeight}px)`,
    });
  }

  /**
   * corresponding closing animation for slideFromBottom:
   * - slides the modal out to the bottom
   * - sets the focus to the caller
   */
  slideFromBottomClose() {
    this.$mainWrapper.one('transitionend', () => {
      this.$element.hide();
      this.focusCaller();
    });

    this.$mainWrapper.css({
      transform: 'translateY(0)',
    });
  }

  /**
   * When the height of the content changes while the modal is opened,
   * scrolling may have to be prevented or the prevention has to be undone.
   */
  onContentHeightChanged() {
    if (this.shouldPreventScrolling()) {
      this.preventScrolling();
    } else {
      this.scrollToPreviousPosition();
    }
  }

  /**
   * Quick and simple check to see if the scrolling should be prevented.
   * This is the case on mobile + tablet and if the content is larger than
   * the modal itself (i.e. the modal is scrollable).
   */
  shouldPreventScrolling() {
    return (
      this.$mainContent.outerHeight() >= $(window).outerHeight() &&
      (ResponsiveHelper.isTablet() || ResponsiveHelper.isMobile())
    );
  }

  /**
   * Prevent scrollable page when the modal is open.
   * We achieve this by setting the body to overflow: hidden and setting the
   * height to 100%, thus effectively cutting the rest of the page off. This
   * scrolls to the top of the page, so we also have to save the previous
   * scroll state.
   *
   * Additionally, we prevent bouncy body scrolling which can lead to subpar
   * experience on iOS devices.
   *
   * One day, this can be solved by `overscroll-behavior: contain;` which
   * "contains" the scrolling to the current container (exactly what we
   * need), but for now it's not supported everywhere yet:
   * https://caniuse.com/#feat=css-overscroll-behavior
   */
  preventScrolling() {
    this.previousScrollPosition = $(window).scrollTop();
    $('html').addClass('h-prevent-scrolling');

    if (this.browserSupportsElasticScrolling) {
      BouncePrevention.enable();
    }
  }

  /**
   * If, upon opening the modal, the ability to scroll was removed, we give it back now. This means:
   * - removing the class that prevents the scrolling
   * - scrolling back to the previously saved scroll position
   * - additionally we re-enable bouncy body scrolling
   *
   * This makes it appear as if we never even scrolled away.
   */
  scrollToPreviousPosition() {
    if (this.previousScrollPosition !== null) {
      $('html').removeClass('h-prevent-scrolling');
      $(window).scrollTop(this.previousScrollPosition);
      this.previousScrollPosition = null;
    }

    if (this.browserSupportsElasticScrolling) {
      BouncePrevention.disable();
    }
  }

  /**
   * When the modal is open, make it accessible to screenreaders and
   * hide the rest of the page from them.
   *
   * @param modalIsOpened {boolean}
   */
  setA11YProperties(modalIsOpened) {
    if (modalIsOpened) {
      this.$A11YElements.attr({
        'aria-hidden': true,
        role: 'presentation',
      });
    } else {
      this.$A11YElements.removeAttr('aria-hidden').removeAttr('role');
    }
  }

  /**
   * In some cases the same modal may be opened by different caller.
   *
   * @param $caller jQuery.Element
   */
  setCaller($caller) {
    if ($caller) {
      this.$caller = $caller;
    }
  }

  /**
   * Set the focus on  the element that opened the modal (if there is one)
   */
  focusCaller() {
    if (this.$caller?.length > 0) {
      setFocus(this.$caller);
    }
  }
}
