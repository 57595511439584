/* global UC_UI */
import React from 'react';
import { SRFBridge } from 'srf-native-bridge';

import Button from '@fec/frontend/elements/buttons/button/button';
import SvgIcon from '@fec/frontend/elements/icons/svg-icon/svg-icon';

import './PrivacyWall.scss';

export const PrivacyWall = ({ onAccept = () => {} }) => {
  const canOpenSettings = !SRFBridge.isRunningInApp();

  return (
    <div className="privacywall">
      <div className="privacywall__icon" aria-hidden={true} role="presentation">
        <SvgIcon styleModifier="svg-icon--view-off svg-icon--adjust-to-container" />
      </div>

      <p className="privacywall__title">
        Möchten Sie diesen ergänzenden Inhalt laden?
      </p>

      <details className="privacywall__details">
        <summary className="privacywall__text">
          Hinter diesem Platzhalter verbirgt sich ein externer Inhalt (z.B. ein
          Social Media Post oder ein Video), den unsere Redaktion empfiehlt.
          <span className="privacywall__link">
            Mehr <SvgIcon styleModifier="svg-icon--arrow-down-red-tiny" />
          </span>
        </summary>
        <p className="privacywall__text">
          Dieser Platzhalter erscheint, weil Sie die Verwendung von «Social
          Media und externen Drittsystemen» in Ihren Datenschutz-Einstellungen
          deaktiviert haben. Klicken Sie auf «Inhalt laden», um diesen Inhalt
          einmalig anzuzeigen. Dabei können Cookies gesetzt und Daten an externe
          Anbieter übertragen werden. Passen Sie Ihre Datenschutz-Einstellungen
          an, um solche Inhalte dauerhaft anzeigen zu können.
        </p>
      </details>

      <div className="privacywall__buttons">
        <div>
          <Button
            styleModifier="button--mixed-case button--no-spacing button--full-width"
            buttonText="Inhalt laden"
            onClick={onAccept}
          />
        </div>
        {canOpenSettings && (
          <div>
            <Button
              styleModifier="button--secondary button--mixed-case button--no-spacing button--full-width"
              buttonText="Einstellungen anpassen"
              onClick={() => UC_UI.showSecondLayer()}
            />
          </div>
        )}
      </div>
    </div>
  );
};
