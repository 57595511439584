export const KEYCODES = {
  enter: 13,
  tab: 9,
  space: 32,
  escape: 27,
  up: 38,
  down: 40,
  left: 37,
  right: 39,
  home: 36,
  end: 35,
  pageUp: 33,
  pageDown: 34,
};
