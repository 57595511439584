import TouchDetection from '@fec/frontend/foundation/client/touch';

export function canShareNatively() {
  return TouchDetection.isTouchSupported() && navigator.share;
}

/**
 * Opens the native share options on touch-enabled devices, if supported.
 */
export function init() {
  if (canShareNatively()) {
    document.addEventListener('click', (event) => {
      const button = event.target.closest('.js-use-native-sharing-on-mobile');
      if (button) {
        navigator
          .share({
            title: button.dataset?.shareTitle,
            url: button.dataset?.shareLink,
          })
          // eslint-disable-next-line no-console
          .catch(console.error);
      }
    });
  }
}
