import React, { useEffect } from 'react';
import useExternalScript from '@fec/frontend/foundation/hooks/ExternalScript';
import {
  CMP_PRIVACY_SHIELD_LOADED,
  triggerEvent,
} from '@fec/assets/js/utils/event';

export const HtmlEmbed = ({ html, sources = [], scripts = [] }) => {
  // execute inline scripts from html embeds
  useEffect(() => {
    scripts.map((script) => {
      eval.call(window, script);
    });
  }, [scripts]);

  // add and execute referenced external scripts
  for (const source of sources) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useExternalScript(source);
  }

  // this allows multiple independent instances of twitter widgets on one page
  // window.twttr.widgets.load() is idempotent and only renders not-yet-rendered widgets
  useEffect(() => {
    if (html.includes('twitter.com') && window.twttr) {
      try {
        window.twttr.widgets.load();
      } catch (e) {
        //bad things happen to bad embeds
      }
    }
    // this allows multiple independent instances of dpa widgets on one page
    if (html.includes('data-app="dpa-') && window.dpaSearchForApps) {
      window.dpaSearchForApps();
    }
    triggerEvent(CMP_PRIVACY_SHIELD_LOADED);
  }, []);

  // React can't render html without a wrapper element
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
