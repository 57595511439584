import React, { useEffect, useRef } from 'react';
import useExternalScript from '@fec/frontend/foundation/hooks/ExternalScript';
import { getConfig } from '@fec/assets/js/utils/config';

export const ObsWidgetEmbed = ({
  obsWidgetType,
  obsWidgetSlug,
  obsWidgetSdkUrl,
  obsWidgetApiKey,
  obsWidgetApiSecret,
}) => {
  const ref = useRef(null);
  const addedWidget = useRef(false);

  useExternalScript(obsWidgetSdkUrl);

  useEffect(() => {
    (function (o, l, y, m, p, i, c, s) {
      o.__OvpSdkObject = p;
      o[p] = o[p] || {};
      o[p].ready = function (f) {
        (o[p].q = o[p].q || []).push(f);
      };
      o[p].r = m;
      o[p].o = i;
      c = l.createElement(y);
      c.async = 1;
      c.src = m;
      s = l.getElementsByTagName(y)[0];
      s.parentNode.insertBefore(c, s);
    })(window, document, 'script', obsWidgetSdkUrl, 'OVP', {
      apiKey: obsWidgetApiKey,
      apiSecret: obsWidgetApiSecret,
      lang: 'de-DE',
      dir: 'ltr',
    });
  }, [obsWidgetSdkUrl, obsWidgetApiKey, obsWidgetApiSecret]);

  useEffect(() => {
    // only load widget if OVP available and widget was not already loaded and ref is available
    if (!window.OVP || !ref.current || addedWidget.current) {
      return;
    }

    window.OVP.ready(() => {
      if (addedWidget.current) {
        return;
      }
      addedWidget.current = true;

      window.OVP.widgets.addWidget({
        type: obsWidgetType,
        root: ref.current,
        id: obsWidgetSlug,
      });

      // set sticky offset of OBS widget so the content doesn't disappear behind the masthead
      if (getConfig().REPRESENTATION === 'web') {
        window.OVP.config.setStickyOffset(56);
      }
    });
  }, [obsWidgetType, obsWidgetSlug, addedWidget.current, ref.current]);

  return <div ref={ref} />;
};
