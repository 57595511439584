/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PublicCommentComplaintsService {
    /**
     * Post a new complaint
     * @returns void
     * @throws ApiError
     */
    public static postPublicApiPublicCommentPostCommentComplaint({
        urn,
        bu,
    }: {
        /**
         * URN this complaint relates to
         */
        urn: string,
        bu: 'srf' | 'rtr',
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/commentsapi/v1/public/{bu}/comments/{urn}/complaints',
            path: {
                'urn': urn,
                'bu': bu,
            },
            errors: {
                404: `Returned when comment was not found`,
                409: `Returned when user complains about comment more than once`,
                500: `Returned when complaint could not be saved`,
            },
        });
    }
}
