import { SRFBridge } from 'srf-native-bridge';
import { TRACK_INTERACTION, triggerEvent } from '@fec/assets/js/utils/event';
import { getConfig } from '@fec/assets/js/utils/config';

export class ClickMeasurement {
  constructor() {
    this.initObserver();
  }

  initObserver() {
    const config = getConfig();

    $('body').on('click', '[data-click-track]', (event) => {
      const $element = $(event.currentTarget);

      triggerEvent(TRACK_INTERACTION, {
        event_source: 'link_click',
        //remove the part that says ', Link öffnet in einem neuen Fenster'
        event_name: $element.clone().children().remove().end().text(),
        event_value: $element.attr('href'),
      });

      if (!SRFBridge.isRunningInApp() || !config.CLICKJACKING_ENABLED) {
        event.preventDefault();
        //open tracked link in new window
        window.open(
          $element.attr('href'),
          $element.attr('target') === '_blank' ? '_blank' : '_self',
        );
      }
    });
  }
}
