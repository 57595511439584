import { SRFBridge } from 'srf-native-bridge';

export class SrgElectionWidgetTracking {
  constructor(webtrekk) {
    this.webtrekk = webtrekk;

    window.srgElectionWidgetTracking = (trackingObject) => {
      if (SRFBridge.isRunningInApp()) {
        let metadata = { webtrekk: trackingObject };
        SRFBridge.trackEvent(metadata);
      } else if (this.webtrekk) {
        this.webtrekk.sendInfo({
          linkId: trackingObject.event_type,
          customClickParameter: {
            1: trackingObject.event_source,
            2: trackingObject.event_type,
            3: trackingObject.event_name,
          },
        });
      }
    };
  }
}
