import { useState } from 'react';

// forcing the rerendering of a component
// see: https://stackoverflow.com/questions/46240647/react-how-to-force-a-function-component-to-render/53837442#53837442

export const useForceUpdate = () => {
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
};

export default useForceUpdate;
