import '../../../assets/article/css/static-banner.css';
import { getConfig } from '@fec/assets/js/utils/config';

let config = getConfig();

export function init() {
  import(
    /* webpackChunkName: 'srf-static-banner' */ 'script-loader!../../../../../../../src/SRF/CMSSiteBundle/ezpublish_legacy/srf_shared/design/standard/javascript/srf-static-banner.js'
  ).then(() => {
    SRF.staticBanner.init(config.ENVIRONMENT, $);
  });
}
