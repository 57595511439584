import { getConfig } from '@fec/assets/js/utils/config';
import { Webtrekk } from '../../common/js/analytics/Webtrekk';
import { SrfIframeTrackingRelay } from './classes/SrfIframeTrackingRelay';

export function init() {
  let config = getConfig();
  if (config.ANALYTICS_WEBTREKK_VALUE_OBJECT) {
    new SrfIframeTrackingRelay(
      new Webtrekk(config.ANALYTICS_WEBTREKK_VALUE_OBJECT),
    );
  }
}
