import React, { useEffect, useState } from 'react';

import { PrivacyWall } from '@fec/frontend/modules/content/embed/PrivacyWall';
import { ACTIVATE_CMP_CATEGORY } from '@fec/assets/js/utils/event';
import { hasCategoryConsent } from '../../../../../common/js/consentManager';

import './embed.scss';
import classNames from 'classnames';

export const PrivacyShield = ({
  title,
  children,
  styleModifier = null,
  contentStyleModifier = null,
  excludeFromCmp = false,
}) => {
  const [hasConsent, setConsent] = useState(
    excludeFromCmp ? true : hasCategoryConsent('functional'),
  );
  const handleCmpDecision = (event) => {
    if (event.detail.category === 'functional') {
      setConsent(true);
    }
  };

  // react on changes from CMP overlay
  useEffect(() => {
    if (!excludeFromCmp) {
      window.addEventListener(ACTIVATE_CMP_CATEGORY, handleCmpDecision);
    }

    return () => {
      window.removeEventListener(ACTIVATE_CMP_CATEGORY, handleCmpDecision);
    };
  }, [handleCmpDecision, excludeFromCmp]);

  return (
    <div className={classNames('embed', styleModifier)}>
      {title && <h2 className="embed__title">{title}</h2>}

      {hasConsent && (
        <div className={classNames('embed__content', contentStyleModifier)}>
          {children}
        </div>
      )}
      {!hasConsent && (
        <PrivacyWall onAccept={() => setConsent(true)}></PrivacyWall>
      )}
    </div>
  );
};
