import { SRFBridge } from 'srf-native-bridge';

export class SrfIframeTrackingRelay {
  constructor(webtrekk) {
    this.webtrekk = webtrekk;
    this.initObserver();
  }

  initObserver() {
    window.addEventListener(
      'message',
      (event) => {
        this.receiveMessage(event);
      },
      false,
    );
  }

  receiveMessage(event) {
    try {
      let message = JSON.parse(event.data);
      if (
        typeof message.event_id !== 'undefined' &&
        message.event_id === 'srf_gichet'
      ) {
        this.track(message);
      }
    } catch (e) {
      return false;
    }
  }

  track(message) {
    if (SRFBridge.isRunningInApp()) {
      let metadata = {
        webtrekk: {
          event_source: message.event_source,
          event_name: message.event_name,
          event_value: message.event_value,
          event_type: message.event_type,
        },
      };

      SRFBridge.trackEvent(metadata);
    } else {
      this.webtrekk.sendInfo({
        linkId: message.event_type,
        customClickParameter: {
          1: message.event_source,
          3: message.event_name,
          4: message.event_value,
        },
      });
    }
  }
}
