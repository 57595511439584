import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment';

import ClientStorage from '@fec/frontend/foundation/client/storage';
import { Trans } from '@fec/assets/js/utils/trans';
import {
  GET_TEASER_SIZE_FROM_CSS,
  PLACEHOLDER_SIZE,
} from '@fec/frontend/modules/content/teaser-ng/teaser-ng';
import './teaser-meta.scss';
import { OpenAPI, PublicThreadsService } from '@Comments/openapi/client';
import Urn from '@fec/frontend/foundation/client/urn';
import { readCommentsApiEndpoint } from '@fec/assets/js/utils/meta-tags';
import classNames from 'classnames';
import useForceUpdate from '@fec/frontend/foundation/hooks/ForceUpdate';
import { useIntersectionObserver } from '@fec/frontend/foundation/hooks/IntersectionObserver';
import '@fec/frontend/foundation/translations/react_i18n_init';
import { useTranslation } from 'react-i18next';

export const STORAGE_KEY = 'srf:item:seen:status';

const FRESHLY_PUBLISHED_TIMEFRAME_IN_SECONDS = 3600 * 2; // Q: How old can something be to still be counted as new? A: 2 hours

const STATIC_TEASER_INFO_CLASSNAME = 'js-teaser-info-static';

export const init = () => {
  OpenAPI.BASE = readCommentsApiEndpoint();

  Array.from(document.getElementsByClassName('js-teaser-meta')).forEach(
    (element) => {
      let size = element.dataset.teaserMetaSize;
      if (size === PLACEHOLDER_SIZE) {
        size = GET_TEASER_SIZE_FROM_CSS(element.closest('.js-teaser-ng'));
      }

      const root = createRoot(element);
      root.render(
        <TeaserMeta
          urn={element.dataset.teaserMetaSourceId}
          leftStaticEntries={Array.from(
            element.getElementsByClassName(STATIC_TEASER_INFO_CLASSNAME),
          )}
          hasVideo={element.dataset.teaserMetaVideo === 'true'}
          hasAudio={element.dataset.teaserMetaAudio === 'true'}
          hasImageGallery={element.dataset.teaserMetaImageGallery === 'true'}
          hasComments={element.dataset.teaserMetaComments}
          publishedAt={element.dataset.teaserMetaPublishedAt}
          publishedAtPermanent={element.dataset.teaserMetaPublishedAtPermanent}
          modifiedAt={element.dataset.teaserMetaModifiedAt}
          startedAt={element.dataset.teaserMetaStartedAt}
          endedAt={element.dataset.teaserMetaEndedAt}
          tickerType={element.dataset.teaserTickerType}
          size={size}
        />,
      );
    },
  );
};

const TeaserMeta = ({
  urn,
  leftStaticEntries,
  hasVideo,
  hasAudio,
  hasImageGallery,
  hasComments,
  publishedAt,
  publishedAtPermanent,
  startedAt,
  endedAt,
  modifiedAt,
  tickerType,
  size,
}) => {
  const ref = useRef(null);
  const visible = useIntersectionObserver(ref, {
    threshold: 0,
    rootMargin: '32px',
  });

  return (
    <>
      <div ref={ref} className="teaser-meta__ltr">
        <StaticItems staticItems={leftStaticEntries} />
        <Live started={startedAt} ended={endedAt} size={size} type={tickerType}>
          <Seen urn={urn} modifiedDate={modifiedAt}>
            <LastUpdate
              size={size}
              publishedDate={publishedAt}
              publishedPermanentDate={publishedAtPermanent}
              modifiedDate={modifiedAt}
            />
          </Seen>
        </Live>
        <MediaIcon
          size={size}
          hasVideo={hasVideo}
          hasAudio={hasAudio}
          hasGallery={hasImageGallery}
        />
      </div>
      <div className="teaser-meta__rtl">
        {hasComments && <Comments key={urn} urn={urn} visible={visible} />}
      </div>
    </>
  );
};

const StaticItems = ({ staticItems }) => {
  return (
    <>
      {staticItems.map((htmlElement, index) => (
        <span
          key={index}
          dangerouslySetInnerHTML={{ __html: htmlElement.outerHTML }}></span>
      ))}
    </>
  );
};

const Live = ({ started, ended, size, type, children }) => {
  const { t } = useTranslation();

  if (size.includes('size-micro')) {
    return children;
  }
  const trans = new Trans();
  if (started) {
    let isLive = true;
    const startedAt = moment(started);
    const age = moment.duration(moment().diff(startedAt)).asSeconds();

    if (ended) {
      const endedAt = moment(ended);
      const diff = moment.duration(moment().diff(endedAt)).asSeconds();

      // Event ended, nothing to show here
      if (diff > 0) {
        isLive = false;
      }
    }
    const getLabelByType = (type) => {
      if (type === 'live-chat') {
        return t('Aktiv');
      }
      if (type === 'newsticker') {
        return t('Ticker läuft');
      }
      if (type === 'livestream') {
        return t('Livestream');
      }
      return t('Live');
    };
    if (isLive) {
      let label = '';
      if (size.includes('size-l') || size.includes('size-xl')) {
        label = getSimplifiedDateLabel(startedAt, 'live', trans);
      } else {
        label = getRelativeTime(age, 'live', trans);
      }

      return (
        <span
          className={classNames('teaser-info', {
            'teaser-info--live': age >= 0,
            'teaser-info--live-pre': age < 0,
          })}>
          <strong>{getLabelByType(type)} </strong>
          <span>{label}</span>
        </span>
      );
    }
  }
  return children;
};

const MediaIcon = ({ hasVideo, hasAudio, hasGallery, size }) => {
  if (size.includes('size-micro')) {
    return null;
  }

  const trans = new Trans();

  let mediaType = 'none';
  if (hasVideo) {
    mediaType = 'video';
  } else if (hasAudio) {
    mediaType = 'audio';
  } else if (hasGallery) {
    mediaType = 'gallery';
  } else {
    return null;
  }
  return (
    <span
      className={classNames('teaser-info', {
        'teaser-info--video': mediaType === 'video',
        'teaser-info--audio': mediaType === 'audio',
        'teaser-info--image': mediaType === 'gallery',
      })}>
      {trans.getGlobal(`i18n:teaser:with:${mediaType}`)}
    </span>
  );
};
const LastUpdate = ({
  size,
  publishedDate = null,
  publishedPermanentDate = null,
  modifiedDate = null,
}) => {
  if (size.includes('size-micro')) {
    return null;
  }

  if (publishedDate === null || modifiedDate === null) {
    return null;
  }

  const publishedAt = moment(publishedDate);
  const modifiedAt = moment(modifiedDate);

  const trans = new Trans();
  //update
  if (publishedAt.diff(modifiedAt) < 0) {
    const age = moment.duration(moment().diff(modifiedAt)).asSeconds();

    return (
      <span
        className={classNames('teaser-info', {
          'teaser-info--with-recent-updates':
            age < FRESHLY_PUBLISHED_TIMEFRAME_IN_SECONDS,
          'teaser-info--with-updates':
            age >= FRESHLY_PUBLISHED_TIMEFRAME_IN_SECONDS,
        })}>
        {age < FRESHLY_PUBLISHED_TIMEFRAME_IN_SECONDS
          ? getRelativeTime(age, 'new', trans)
          : trans.getGlobal('i18n:status:updated')}
      </span>
    );
  }
  const age = moment.duration(moment().diff(publishedAt)).asSeconds();
  if (age <= FRESHLY_PUBLISHED_TIMEFRAME_IN_SECONDS) {
    return (
      <span className={classNames('teaser-info', 'teaser-info--new')}>
        {getRelativeTime(age, 'new', trans)}
      </span>
    );
  }

  if (size === 'list') {
    return (
      <span className={'teaser-info teaser-info--permanent-date'}>
        {getPermanentDateString(publishedPermanentDate, trans)}
      </span>
    );
  }
  return null;
};
const Seen = ({ urn, modifiedDate, children }) => {
  const trans = new Trans();
  const seenItems = ClientStorage.getItemJsonParsed(STORAGE_KEY, []);
  const itemInStorage = seenItems.find((lsItem) => lsItem.urn === urn);
  const forceRerender = useForceUpdate();

  const enforceRendering = () => {
    forceRerender();
  };

  useEffect(() => {
    document.addEventListener('pageshow', enforceRendering);
    document.addEventListener('visibilitychange', enforceRendering);
    return () => {
      document.removeEventListener('pageshow', enforceRendering);
      document.removeEventListener('visibilitychange', enforceRendering);
    };
  }, [enforceRendering]);

  if (itemInStorage) {
    const modifiedAt = moment(modifiedDate);
    const seenAt = moment(itemInStorage.timestamp);

    // If item was seen after modification date, we can remove modified and published items
    if (modifiedAt.diff(seenAt) < 0) {
      return (
        <span className="teaser-info teaser-info--visited">
          {trans.getGlobal('i18n:status:read')}
        </span>
      );
    }
  }
  return children;
};

const Comments = ({ urn, visible }) => {
  const trans = new Trans();

  const [loading, setLoading] = useState(true);
  const [commentCount, setCommentCount] = useState(0);
  const [threadState, setThreadState] = useState('closed');

  useEffect(() => {
    if (loading && visible && Urn.getType(urn) === 'article') {
      PublicThreadsService.getApiPublicThreadStats({
        bu: Urn.getBusinessunit(urn),
        urn: urn,
      })
        .then(({ commentsPublished, threadState }) => {
          setCommentCount(commentsPublished);
          setThreadState(threadState);
        })
        .catch(() => setThreadState('error'))
        .finally(() => setLoading(false));
    }
  }, [urn, visible, loading]);

  if (loading) {
    return null;
  }

  if (threadState === 'error') {
    return null;
  }

  return (
    <span
      role="presentation"
      aria-label={trans.translatedWithPlaceHolder(
        threadState === 'open'
          ? 'i18n:teaser:comments:open'
          : 'i18n:teaser:comments:closed',
        commentCount,
      )}
      className={classNames('teaser-info', 'teaser-info--comments', {
        'teaser-info--comments-closed': threadState === 'closed',
      })}>
      {/* if there are 0 comments but they're open, only show the icon but not the number */}
      {commentCount > 0 && commentCount}
    </span>
  );
};

/**
 * From the age of an article/audio/video, create a string describing the
 * relative time since then. E.g. "Vor 10 Minuten" or "seit einem Tag".
 * Differentiate between seconds, minutes, hours and days.
 * Prevent having 60 seconds or minutes (1 minute/1 hour are used then)
 *
 * @param inputAge {number} age of entity in seconds
 * @param type {string} e.g. 'new', 'live'
 * @param trans
 * @returns {string}
 */
const getRelativeTime = (inputAge, type, trans) => {
  if (!inputAge) {
    return '';
  }

  let formattedAgeString = '';
  const past = inputAge >= 0;
  const age = Math.abs(inputAge);

  if (age < 60) {
    // published in the last minute
    formattedAgeString = trans.translatedWithPlaceHolder(
      `i18n:status:${type}:${past ? 'ago' : 'in'}:seconds`,
      Math.round(age),
    );
  } else if (age < 3570) {
    // 3570 is 30 seconds less than an hour to avoid "60 minutes ago"
    // published in the last hour
    let minutes = Math.round(age / 60);

    formattedAgeString = trans.translatedWithPlaceHolder(
      `i18n:status:${type}:${past ? 'ago' : 'in'}:${
        minutes === 1 ? 'minute' : 'minutes'
      }`,
      minutes,
    );
  } else if (age < 36000) {
    // younger than 10 hours
    // published in the last two hours
    const hours = Math.round(age / 3600);

    formattedAgeString = trans.translatedWithPlaceHolder(
      `i18n:status:${type}:${past ? 'ago' : 'in'}:${
        hours === 1 ? 'hour' : 'hours'
      }`,
      hours,
    );
  } else {
    // 10 hours or older
    const days = Math.ceil(age / 3600 / 24);
    formattedAgeString = trans.translatedWithPlaceHolder(
      `i18n:status:${type}:${past ? 'ago' : 'in'}:${
        days === 1 ? 'day' : 'days'
      }`,
      days,
    );
  }

  return formattedAgeString;
};

const getSimplifiedDateLabel = (inputDate, type, trans) => {
  const age = moment.duration(moment().diff(inputDate)).asSeconds();
  const past = age > 0;
  return trans.translatedWithPlaceHolder(
    `i18n:status:${type}:${past ? 'since' : 'pre'}`,
    inputDate.format('DD.MM.YYYY, H:mm'),
  );
};

const getPermanentDateString = (date, trans) => {
  let dateObject = moment(date);
  let prefix = '';
  let timeString = dateObject.format('H:mm');
  let suffix = $('body')?.data('bu')?.toLowerCase() === 'rtr' ? '' : ' Uhr';

  if (
    // today
    dateObject >= moment().startOf('day') &&
    dateObject < moment().endOf('day')
  ) {
    prefix = trans.getGlobal('i18n:date:today') + ', ';
  } else if (
    // yesterday
    dateObject >= moment().subtract(1, 'day').startOf('day') &&
    dateObject < moment().subtract(1, 'day').endOf('day')
  ) {
    prefix = trans.getGlobal('i18n:date:yesterday') + ', ';
  } else {
    // older than yesterday or newer than today
    timeString = dateObject.format('DD.MM.YYYY');
    suffix = '';
  }

  return `${prefix}${timeString}${suffix}`;
};
