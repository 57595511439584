import { SRFBridge } from 'srf-native-bridge';
import {
  LEGACY_TRACK_INTERACTION,
  onEvent,
  TRACK_INTERACTION,
} from '@fec/assets/js/utils/event';

// By default, event_type is 'click' unless specified otherwise.
const defaultData = {
  event_type: 'click',
};

// See docs/tracking.md for further information
export class InteractionMeasurement {
  constructor(webtrekk) {
    this.webtrekk = webtrekk;

    this.initObserver();
  }

  initObserver() {
    $('body').on('click', '[data-event-track]', (event) => {
      let $element = $(event.currentTarget);
      let trackingRaw = $element.data('event-track');
      let trackingObject = {};
      trackingRaw.split('&').forEach((kvPair) => {
        const [key, value] = kvPair.split('=');
        trackingObject[key] = decodeURIComponent(value);
      });

      this.sendData(Object.assign({}, defaultData, trackingObject));
    });

    onEvent({
      eventName: [TRACK_INTERACTION, LEGACY_TRACK_INTERACTION],
      eventHandler: (event) =>
        this.sendData(Object.assign({}, defaultData, event.detail)),
    });
  }

  sendData(trackingObject) {
    if (SRFBridge.isRunningInApp()) {
      let metadata = { webtrekk: trackingObject };
      SRFBridge.trackEvent(metadata);
    } else if (this.webtrekk) {
      let payload = {
        linkId: trackingObject.event_type,
        customClickParameter: {},
      };

      // Fill in tracking values
      if (trackingObject.event_source) {
        payload.customClickParameter['1'] = trackingObject.event_source; // ck1
      }
      if (trackingObject.event_name) {
        payload.customClickParameter['3'] = trackingObject.event_name; // ck3
      }
      if (trackingObject.event_value) {
        payload.customClickParameter['4'] = trackingObject.event_value; // ck4
      }

      this.webtrekk.sendInfo(payload);
    }
  }
}
