import moment from 'moment';
import { GET_TEASER_SIZE_FROM_CSS } from '@fec/frontend/modules/content/teaser-ng/teaser-ng';

const HIDDEN_CLASS_NAME = 'h-element--hide';
const TEASER_LABEL_PRE = 'teaser-label--pre';
const TEASER_LABEL_LIVE = 'teaser-label--live';
const TEASER_LABEL_CLASS_NAME = 'js-teaser-label';

const PRE_LABEL_HIDDEN_TEASER_SIZES = ['size-s'];
const PRE_LABEL_HIDDEN_CLASS_NAME = 'teaser-label--hide-pre';

export function init() {
  $(`.${TEASER_LABEL_CLASS_NAME}`).each((_, element) => {
    const $teaserLabel = $(element);

    // remove JS class so that the script doesn't trigger again
    $teaserLabel.removeClass(TEASER_LABEL_CLASS_NAME);

    // PRE-LABEL-VISIBILITY FOR TEASER-NG
    // set teaser-label--hide-pre according to teaser type
    // caveat: will not be executed again, if the teaser type changes (i.e. when resizing the window)
    let parentTeaserNG = $teaserLabel.parents('.js-teaser-ng')[0];
    if (parentTeaserNG) {
      let parentSize = GET_TEASER_SIZE_FROM_CSS(parentTeaserNG);
      if (PRE_LABEL_HIDDEN_TEASER_SIZES.includes(parentSize)) {
        $teaserLabel.addClass(PRE_LABEL_HIDDEN_CLASS_NAME);
      }
    }

    // get start/end time from data attributes
    const info = $teaserLabel.data();
    const startDate = info.starttime ? moment(info.starttime) : false;
    const endDate = info.endtime ? moment(info.endtime) : false;

    // no start date --> can't deal with it (needed for all calculations)
    if (!startDate) {
      // just show the label as-is
      $teaserLabel.removeClass(HIDDEN_CLASS_NAME);
      return;
    }

    const now = moment();
    const age = moment.duration(now.diff(startDate)).asSeconds();

    if (endDate && moment.duration(now.diff(endDate)).asSeconds() > 0) {
      // Event is over:
      $teaserLabel.remove();
      return;
    } else if (age > 0) {
      // Event is ongoing:
      // set live class on the teaser-label and show it
      $teaserLabel
        .addClass(TEASER_LABEL_LIVE)
        .removeClass([HIDDEN_CLASS_NAME, TEASER_LABEL_PRE]);
    } else {
      // Event is not yet ongoing:
      // set pre-live class on the teaser-label and show it
      $teaserLabel
        .addClass(TEASER_LABEL_PRE)
        .removeClass([HIDDEN_CLASS_NAME, TEASER_LABEL_LIVE]);
    }
  });
}
