export class SwissMediaDataHub {
  constructor() {
    this._config = null;
  }

  set config(value) {
    this._config = value;
  }

  sendInfo() {
    let s = document.createElement('script'),
      el = document.getElementsByTagName('script')[0];
    s.async = true;
    s.src = `${this._config.track_domain}/cs/6036016/beacon.js`;
    el.parentNode.insertBefore(s, el);
  }
}
