import React from 'react';
import { FacebookEmbed as Facebook } from 'react-social-media-embed';

export const FacebookEmbed = ({ url }) => (
  <Facebook
    url={url}
    width="100%"
    style={{
      maxWidth: '500px',
    }}
  />
);
