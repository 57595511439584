import { Tools } from './Tools';

export class Chartbeat extends Tools {
  constructor(webtrekkParams, businessUnit, path) {
    super();
    this.webtrekkParams = webtrekkParams;
    this.businessUnit = businessUnit;
    this.path = path;

    this.setConfig();
    this.loadExternalJs('//static.chartbeat.com/js/chartbeat.js');
  }

  setConfig() {
    if (typeof window._sf_async_config === 'object') {
      /* global _sf_async_config */
      let rubric =
        this.webtrekkParams.contentGroup[7] !== null // subrubric available
          ? ',' +
            this.webtrekkParams.contentGroup[6] +
            ' | ' +
            this.webtrekkParams.other['rubric']
          : ''; // using the most specific rubric
      _sf_async_config.sections =
        this.webtrekkParams.contentGroup[6] +
        rubric +
        ',' +
        this.webtrekkParams.customParameter[1];
      _sf_async_config.authors = this.webtrekkParams.contentGroup[1];
      _sf_async_config.type = this.webtrekkParams.customParameter[1];
      _sf_async_config.path = this.path;
      _sf_async_config.domain = this.businessUnit + '.ch';
    }
  }
}
