import { TRACK_INTERACTION, triggerEvent } from '@fec/assets/js/utils/event';
import { Tools } from './Tools';

export const init = () => {
  const tools = new Tools();
  tools.loadExternalJs('//jptdpz43r4.kameleoon.eu/kameleoon.js').then(() => {
    window.kameleoonBridge = {
      trackInteraction: (data) => triggerEvent(TRACK_INTERACTION, data),
    };
  });
};
