import { Tools } from './Tools';
import { SRFBridge } from 'srf-native-bridge';
import {
  GET_TEASER_SIZE_FROM_CSS,
  PLACEHOLDER_POS,
  PLACEHOLDER_SIZE,
} from '@fec/frontend/modules/content/teaser-ng/teaser-ng';

export class ModuleMeasurement extends Tools {
  constructor() {
    super();
    this.initObserver();
  }

  initObserver() {
    let self = this;
    let events = SRFBridge.isRunningInApp()
      ? 'click'
      : 'mousedown touchstart selected';

    $('body').on(events, '[data-referrer-track]', function (event) {
      let data = $(this).data('referrer-track');
      data = self.getDynamicData(event, data);
      self.saveReferrerInfo(event, data);
    });
  }

  saveReferrerInfo(event, data) {
    let $target = $(event.target).closest('a');
    if (
      ($target.is('a') &&
        $target.attr('href') &&
        !$target.attr('href').startsWith('#', 0)) ||
      $(event.target).is('input')
    ) {
      let referrerData = JSON.parse(this.getData('srf:analytics:mod'));
      if (referrerData) {
        referrerData = Object.assign(referrerData, this.parseValue(data));
      } else {
        referrerData = this.parseValue(data);
      }
      this.setData('srf:analytics:mod', JSON.stringify(referrerData));
      this.setData('srf:analytics:mod:app', JSON.stringify(referrerData));
    }
  }

  parseValue(value) {
    let result = {};
    if (typeof value === 'string' && value.length) {
      for (let row of value.split('&')) {
        if (typeof row === 'string' && row.length) {
          let parts = row.split('=');
          result[parts[0]] = parts[1];
        }
      }
    }

    return result;
  }

  // Not all data is known during render time, because teaser can be shuffled around
  getDynamicData(event, data) {
    if (data.includes(PLACEHOLDER_SIZE)) {
      // get size directly from content of ::before pseudo element (see teaser-ng.scss)
      const teaserSize = GET_TEASER_SIZE_FROM_CSS(event.currentTarget);

      data = data.replaceAll(PLACEHOLDER_SIZE, teaserSize);
    }

    if (data.includes(PLACEHOLDER_POS)) {
      // calculate position direct from position in DOM
      const teaser = event.currentTarget.parentNode;
      const teaserList = Array.from(teaser.parentNode.children);
      const position = teaserList.indexOf(teaser);

      data = data.replaceAll(PLACEHOLDER_POS, position);
    }

    return data;
  }
}
