/* global sg_beacon */
import Cookies from 'js-cookie';
import { SRFBridge } from 'srf-native-bridge';
import { Tools } from './Tools';
import { REFERRER_STORAGE } from './Webtrekk';
import {
  CLOSE_MODAL,
  MODAL_CLOSED,
  onEvent,
  OPEN_MODAL,
  triggerEvent,
} from '@fec/assets/js/utils/event';

const SURVEY_MODAL_ID = 'modal-survey';
const SURVEY_OPENED_COOKIE_VALUE = 'Opened';
const SURVEY_DISMISSED_COOKIE_VALUE = 'Dismissed';

export class SurveyGizmo extends Tools {
  constructor() {
    super();
    this._webtrekkSurveyGizmoValueObject = null;
    this._webtrekkValueObject = null;
    this._webtrekkClass = null;
    this._surveyGizmoBeacon = null;
    this._env = null;
    this._seoUrl = null;
    // link looks like this: https://survey.alchemer.eu/s3/5626808/32e62033d6b8?navigation_environment=prod&page_url=%2F&content_since_publication_d=2197&content_title_pretty=Home&content_category_1=Home&content_id=5380214&content_page_type=Landingpage&content_publication_date=2014.11.18&content_publication_date_concatenated=20141118&content_publication_time=11%3A18&content_publication_time_concatenated=1118&content_publication_datetime_concatenated=201411181118&content_title=survista&content_publication_version=377&navigation_bu_distributer=RTR&navigation_property_type=web&navigation_app_site_name=rtr.ch&navigation_level_1=Home&http_status_code=200
    this.surveyId = null;
    // if nothing else is set, mute a dismissed survey for 14 days
    this.muteForDays = 14;
    this.invitationDataUrl = '/api/public/surveys';
    this.locationAttributes = [];
    this.ceid = null;
    this.navAppSiteName = null;
  }

  set webtrekkSurveyGizmoValueObject(value) {
    this._webtrekkSurveyGizmoValueObject = value;
  }

  set webtrekkValueObject(value) {
    this._webtrekkValueObject = value;
  }

  set webtrekkClass(value) {
    this._webtrekkClass = value;
  }

  set surveyGizmoBeacon(value) {
    this._surveyGizmoBeacon = value;
  }

  set env(value) {
    this._env = value;
  }

  set seoUrl(value) {
    this._seoUrl = value;
  }

  init() {
    if (
      this._webtrekkValueObject &&
      this._webtrekkSurveyGizmoValueObject &&
      this._surveyGizmoBeacon
    ) {
      this.prepareEnv();
      this.setBeaconData();
      this.prepareSampleIdentifier();
      this.gatherLocationAttributes();
      this.checkForInvitation();
    }
  }

  /* collects the relevant attributes of the current location (page, article) and stores them as urns in an array.
   * the location attributes and the invitation conditions share the same urn convention:
   *
   * urn:srf:article:<id> Article with specific id
   * urn:srf:landingpage:<id> Landingpage with specific id
   * urn:srf:representation:<name> Specific representation (web|webview|webview-light)
   * urn:srf:portal:<portal-identifier> Specific portal by identifier (i.e. urn:srf:portal:kultur)
   * urn:srf:app:<app-identifier> Specific app (i.e. urn:srf:app:web -> website, urn:srf:app:srf-news-ios -> News-App iOS)
   */
  gatherLocationAttributes() {
    let businessUnit = $('body').attr('data-bu').toLowerCase();
    let attributes = [];

    // add urn of this location (can be landingpage, article, ... depending on page type)
    attributes.push($('meta[name="srf:urn"]').attr('content'));

    // add representation
    let representation = $('body').attr('data-representation');
    attributes.push(`urn:${businessUnit}:representation:${representation}`);

    // add portal
    attributes.push($('meta[name="srf:portal:urn"]').attr('content'));

    // add app-identifier
    if (representation === 'web') {
      attributes.push(`urn:${businessUnit}:app:web`);
    } else {
      let appData = this.getData('sg_app_data');
      if (appData) {
        appData = JSON.parse(appData);
        let appIdent = appData.navigation_app_site_name;
        if (appIdent) {
          attributes.push(`urn:${businessUnit}:app:${appIdent}`);
        }
      }
    }
    this.locationAttributes = attributes.filter((item) => item); // remove empty strings from array
  }

  /* get all survey invitations from the api and display the first invitation with matching conditions (if there's any) */
  checkForInvitation() {
    fetch(this.invitationDataUrl)
      .then((response) => response.json())
      .then((invitations) => {
        /* Get the first invitation that matches all conditions */
        const invitationToShow = invitations.find(
          (invitation) =>
            this.matchesLocalCriteria(invitation, this.locationAttributes) &&
            !this.isSurveyAlreadyTaken(invitation) &&
            !this.isPoliteGracePeriod(invitation) &&
            this.isUserInSampleSize(invitation),
        );

        /* Display invitation modal */
        if (invitationToShow) {
          this.surveyId = invitationToShow.surveyId;
          this.muteForDays = invitationToShow.polite || this.muteForDays;
          this.showInvitation(invitationToShow);
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.warn('Error while getting survey list', error));
  }

  matchesLocalCriteria(invitation, localCriteria) {
    return invitation.conditions.every((condition) =>
      localCriteria.includes(condition),
    );
  }

  isUserInSampleSize(invitation) {
    const userSampleNumber = parseInt(Cookies.get('sg_random_sample'), 10);
    const { percentage = 0 } = invitation;
    // a user is part of the sample, if their random number (min 1 max 100) is
    // less or equal than the defined percentage of the survey invitation:
    return userSampleNumber <= percentage;
  }

  /* check cookie if survey was taken already */
  isSurveyAlreadyTaken(invitation) {
    return (
      Cookies.get(`sg_survey_${invitation.surveyId}`) ===
      SURVEY_OPENED_COOKIE_VALUE
    );
  }

  /* did the user dismiss the survey, show it again after at least X days */
  isPoliteGracePeriod(invitation) {
    return (
      Cookies.get(`sg_survey_${invitation.surveyId}`) ===
      SURVEY_DISMISSED_COOKIE_VALUE
    );
  }

  async showInvitation(invitation) {
    const delay = invitation.delay ?? 0;
    const link = this.getSurveyLink(invitation);

    // fill modal with content
    $('.js-survey-invitation-title').text(invitation.title);
    $('.js-survey-invitation-text').append(invitation.text);
    $('.js-survey-invitation-link').attr('href', link);
    $('.js-survey-invitation-link-text').text(invitation.linkText);

    await this.triggerModal(delay);
  }

  getSurveyLink(invitation) {
    let surveySearchParams = new URLSearchParams(
      this._webtrekkSurveyGizmoValueObject,
    );

    this.ceid && surveySearchParams.set('ceid', this.ceid);
    this.navAppSiteName &&
      surveySearchParams.set('navigation_app_site_name', this.navAppSiteName);
    this._env && surveySearchParams.set('navigation_environment', this._env);

    return `${invitation.link}?${surveySearchParams}`;
  }

  triggerModal(delay) {
    return new Promise((resolve) => {
      setTimeout(() => {
        triggerEvent(OPEN_MODAL, { modalId: SURVEY_MODAL_ID });
        this.initModalLinkObservers();
        resolve('resolved');
      }, delay * 1000);
    });
  }

  prepareSampleIdentifier() {
    if (Cookies.get('sg_random_sample') === undefined) {
      let sampleIdentifier = Math.floor(Math.random() * 100) + 1;
      Cookies.set('sg_random_sample', sampleIdentifier, {
        path: '/',
        expires: 60 /* days */,
        domain: '.' + window.location.host,
      });
    }
  }

  prepareEnv() {
    window.SurveyGizmoBeacon = 'sg_beacon';
    window['sg_beacon'] =
      window['sg_beacon'] ||
      function () {
        (window['sg_beacon'].q = window['sg_beacon'].q || []).push(arguments);
      };
  }

  setBeaconData() {
    let refererData = JSON.parse(this.getData(REFERRER_STORAGE));

    sg_beacon('init', this._surveyGizmoBeacon);
    this.applyBeaconData('navigation_environment', this._env);
    this.applyBeaconData('page_url', this._seoUrl);

    if (refererData && document.referrer && refererData[document.referrer]) {
      this.applyBeaconData(
        'content_page_referrer',
        refererData[document.referrer].contentId,
      );
      this.applyBeaconData(
        'navigation_page_referrer',
        refererData[document.referrer].contentId,
      );
    }

    this.applyBeaconData(
      'content_since_publication_d',
      this.getDaysSincePublished(this._webtrekkValueObject.customParameter[33]),
    );
    this.applyBeaconData(
      'srg_mod1',
      this._webtrekkClass.options.customParameter[24],
    );
    this.applyBeaconData(
      'srg_mod2',
      this._webtrekkClass.options.customParameter[25],
    );
    this.applyBeaconData(
      'srg_mod3',
      this._webtrekkClass.options.customParameter[26],
    );
    this.applyBeaconData(
      'srg_mod4',
      this._webtrekkClass.options.customParameter[27],
    );

    $.each(this._webtrekkSurveyGizmoValueObject, (key, value) => {
      this.applyBeaconData(key, value);
    });

    let appData = this.getData('sg_app_data');
    if (appData) {
      appData = JSON.parse(appData);
      this.ceid = appData.ceid || null;
      this.applyBeaconData('ceid', this.ceid);

      this.navAppSiteName = appData.navigation_app_site_name || null;
      this.applyBeaconData('navigation_app_site_name', this.navAppSiteName);
    }
  }

  applyBeaconData(key, value) {
    if (value) {
      sg_beacon('data', key, value);
    }
  }

  initModalLinkObservers() {
    $(document).on('click', '.js-survey-invitation-link', () =>
      this.onSurveyLinkClicked(),
    );

    onEvent({
      eventName: MODAL_CLOSED,
      eventHandler: ({ detail }) => {
        if (detail?.modalId === SURVEY_MODAL_ID) {
          this.onSurveyModalClosed();
        }
      },
    });
  }

  onSurveyLinkClicked() {
    Cookies.set(`sg_survey_${this.surveyId}`, SURVEY_OPENED_COOKIE_VALUE, {
      path: '/',
      expires: 60,
      domain: '.' + window.location.host,
    });
    this.trackCustomClick('SurveyClicked');

    // close the modal, otherwise it could be closed manually which would set
    // the state to `dismissed`
    triggerEvent(CLOSE_MODAL, { modalId: SURVEY_MODAL_ID });
  }

  onSurveyModalClosed() {
    Cookies.set('sg_survey_' + this.surveyId, SURVEY_DISMISSED_COOKIE_VALUE, {
      path: '/',
      expires: this.muteForDays,
      domain: '.' + window.location.host,
    });
    this.trackCustomClick('SurveyDismissed');
  }

  trackCustomClick(action) {
    if (SRFBridge.isRunningInApp()) {
      let payload = {
        webtrekk: {
          event_type: 'hidden_event',
          event_source: 'SurveyGizmo',
          event_name: 'WebsiteIntercept',
          event_value: action,
          navigation_bu_distributer: 'SRF',
          navigation_property_type: 'webview',
          navigation_app_site_name: 'srf.ch',
          survey_id: this.surveyId,
        },
      };
      SRFBridge.trackEvent(payload);
    } else {
      this._webtrekkClass.sendInfo({
        linkId: 'hidden_event',
        customClickParameter: {
          1: 'SurveyGizmo',
          3: 'WebsiteIntercept',
          4: action,
          6: this._webtrekkValueObject.contentGroup[1],
          9: this.surveyId,
          10: this._webtrekkValueObject.contentGroup[2],
          11: this._webtrekkValueObject.contentGroup[3],
        },
      });
    }
  }
}
