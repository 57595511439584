import { Trans } from '@fec/assets/js/utils/trans';
import moment from 'moment';

export function init() {
  $('.js-broadcast-info').each((_, element) => {
    let $broadcastInfo = $(element);
    $broadcastInfo.text(
      getBroadcastDateLine(
        $broadcastInfo.data('starttime'),
        $broadcastInfo.data('endtime'),
      ),
    );
  });
}

/**
 * Returns a formatted broadcast start date and time string
 *
 * @param starttime
 * @param endtime
 * @returns string
 */
let getBroadcastDateLine = function (starttime, endtime) {
  let translator = new Trans();

  let start = moment(starttime);
  let end = moment(endtime);
  let now = moment();

  let suffix = $('body').data('bu') === 'srf' ? ' Uhr' : '';

  // current broadcast
  if (now.isAfter(start) && now.isBefore(end)) {
    return translator.getGlobal('i18n:date:now');
  }
  // today
  if (start.isSame(now, 'day')) {
    return (
      translator.getGlobal('i18n:date:today') +
      ', ' +
      start.format('HH:mm') +
      suffix
    );
  }
  // tomorrow
  if (start.isSame(now.add(1, 'day'), 'day')) {
    return (
      translator.getGlobal('i18n:date:tomorrow') +
      ', ' +
      start.format('HH:mm') +
      suffix
    );
  }
  // default
  return start.format('DD.MM.YYYY, HH:mm') + suffix;
};
