import { useEffect, useState } from 'react';

// Explanation of the options:
// threshold:
//    0.69 means that the observer will call the callback when the target is at least 69% visible
//    0 = callback is called as soon as even one pixel is visible
// root:
//    Element that is used as the viewport for checking visibility of the target.
//    null = viewport is used as the root
// rootMargin:
//    Margin around the root. Can have values similar to the CSS margin property (px, %, etc.)
// more info: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
export const useIntersectionObserver = (ref, options = {}, enabled = true) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const { threshold = 0, root = null, rootMargin = '0px' } = options;

  useEffect(() => {
    if (!enabled) {
      setIsIntersecting(false);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold, root, rootMargin },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options, enabled]);

  return isIntersecting;
};
