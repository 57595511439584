import LazyLoad from 'vanilla-lazyload';
import { SHOW_HIDDEN_TEASER, onEvent } from '@fec/assets/js/utils/event';

const ELEMENT_SELECTOR = '.js-lazyload';

export function init() {
  const lazyContent = new LazyLoad({
    elements_selector: ELEMENT_SELECTOR,
  });

  onEvent({
    eventName: [SHOW_HIDDEN_TEASER],
    eventHandler: () => lazyContent.update(),
  });
}
